import moment from 'moment';
import contractingConfig from 'config/contracting.config.json';
import find from 'lodash/find';

const getJobDescription = (productConfig, jodCode) => {
  if (contractingConfig.occupations) {
    for (let i = 0; i < contractingConfig.occupations.length; i += 1) {
      if (contractingConfig.occupations[i].value === jodCode) {
        return contractingConfig.occupations[i].name;
      }
    }
    return jodCode;
  }
};

const getModality = (productConfig, form, isId) => {
  if (productConfig.modalitiesInfo) {
    const modalitiesGroup = productConfig.modalitiesInfo.modalitiesGroup;
    for (let i = 0; i < modalitiesGroup.length; i += 1) {
      for (let j = 0; j < modalitiesGroup[i].modalities.length; j += 1) {
        const modality = modalitiesGroup[i].modalities[j];
        if (
          modality.modality === form.selectedModality ||
          modality.modalityName === form.selectedModality
        ) {
          return isId
            ? modalitiesGroup[i].modalities[j].modality
            : modalitiesGroup[i].modalities[j].modalityName;
        }
      }
    }
  }
  return null;
};

class PersonData {
  constructor(data) {
    let relationship = data.relationship;
    if (relationship === 'Es el tomador') {
      relationship = '0';
    }
    this.data = {
      nif: data.nif,
      name: data.name,
      surname: data.lastName,
      birthDate: moment.utc(data.birthDate, 'DD/MM/YYYY').toDate(),
      gender: data.genre,
      relationship,
    };
    this.nationalityCode = data.nationality;
    const nationality = find(contractingConfig.nationalities, {
      value: Number.parseInt(data.nationality),
    });
    this.nationalityDescription = nationality ? nationality.name : null;

    this.address = {
      address: data.direction,
      cp: data.postalCode,
      city: data.town,
      state: data.province,
      country: find(contractingConfig.countries, {
        value: Number.parseInt(data.country),
      }).name,
      countryCode: data.country,
    };
    this.contact = {
      phone1: data.mobilePhone,
      phone2: data.phone,
      email: data.email,
    };
    this.jobDescription = {
      cno: data.profession,
      cnoDescription: getJobDescription(data.productConfig, data.profession),
    };
  }
}

class Insured extends PersonData {
  constructor(data) {
    super(data);
    this.isPolicyHolder = data.relationship === 'Es el tomador';
    this.healthState = {
      weight: data.weight,
      height: data.height,
      comments: data.healthComments,
      pathologies: {
        ...data.healthCondition,
      },
    };
    this.coverages = {
      Modalidad: data.modality ? data.modality.toString() : null,
      ImportePrimeraPrima: data.ImportePrimeraPrima,
      ImporteAsegurado: data.ImporteAsegurado,
      ImporteSubsidio: data.ImporteSubsidio,
      Beneficiarios: data.Beneficiarios,
      ...data.coverages,
    };
  }
}

class HomeInfo {
  constructor(data) {
    this.objectInsured = {
      homeDescrip: {
        TipoVivienda: 'Principal',
        EsUnifamiliar: data.form.singleFamily === '1' ? 'Si' : 'No',
        EsDespoblada: 'No',
        AñoConstruccion: data.homeInfo.constructionYear,
        MetrosConstruidos: data.form.squareMeters,
        PuertasBlindadas: data.homeInfo.armouredDoors ? 'Si' : 'No',
        RejasVentanas: data.homeInfo.grates ? 'Si' : 'No',
        Contraventanas: data.homeInfo.shutters ? 'Si' : 'No',
        AlarmaConectada: data.homeInfo.alarm ? 'Si' : 'No',
        VigilanciaPermanente: data.homeInfo.vigilance ? 'Si' : 'No',
      },
      homeAddress: {
        CP: data.homeInfo.postalCode,
        Address: `${data.homeInfo.streetType} ${data.homeInfo.direction}`,
        City: data.homeInfo.town,
        State: data.homeInfo.province,
        Country: data.homeInfo.country,
      },
    };
    this.guaranties = {
      ContinenteRiesgo: data.form.risk ? 'Si' : 'No',
      Continente: data.form.continent
        ? (Number(data.form.continent) * 100).toString()
        : '0',
      Contenido: data.form.content
        ? (Number(data.form.content) * 100).toString()
        : '0',
      JoyasValor: data.homeInfo.lowJewelry
        ? (Number(data.homeInfo.lowJewelry) * 100).toString()
        : '0',
      ObjetosValor: data.homeInfo.lowObjects
        ? (Number(data.homeInfo.lowObjects) * 100).toString()
        : '0',
      JoyasDentroValor: data.homeInfo.highInJewelry
        ? (Number(data.homeInfo.highInJewelry) * 100).toString()
        : '0',
      JoyasFueraValor: data.homeInfo.highOutJewelry
        ? (Number(data.homeInfo.highOutJewelry) * 100).toString()
        : '0',
      ObjetosDentroValor: data.homeInfo.highInObjects
        ? (Number(data.homeInfo.highInObjects) * 100).toString()
        : '0',
      ObjetosFueraValor: data.homeInfo.highOutObjects
        ? (Number(data.homeInfo.highOutObjects) * 100).toString()
        : '0',
    };
    this.mortage = {
      FinancialEntity: data.homeInfo.entity || '',
      MortageNumber: data.homeInfo.entityNumber || '',
      MortageNumberSecond: data.homeInfo.entity2 || '',
      SecondFinancialEntity: data.homeInfo.entityNumber2 || '',
    };
    const date = moment().add(1, 'M');
    const momentDate = `01/${date.month() + 1}/${date.year()}`;
    this.effect = {
      date: moment.utc(momentDate, 'DD/MM/YYYY').toDate(),
    };
  }
}

class Petitions {
  constructor(data) {
    // TODO: Refactorizar de algún modo los dos siguientes campos.
    const importe =
      data.productConfig.calculator &&
      data.productConfig.calculator.type === 'selector'
        ? (
            data.form[data.productConfig.calculator.form.formName] *
            100 *
            12
          ).toString()
        : null;
    const importeSubsidio =
      data.productConfig.calculator &&
      data.productConfig.calculator.type === 'selector'
        ? (
            data.form[data.productConfig.calculator.form.formName] * 100
          ).toString()
        : null;

    // TODO: Cambiar esto para coger el campo del modelo de contratación que se debe poner en el JSON
    let modality = data.form.selectedModality || data.productConfig.id;
    if (data.productConfig.id === 'CPP') {
      modality = 'No profesional';
    }
    if (data.productConfig.id === '5') {
      // Hogar
      modality = modality === '5' ? 'Plus' : 'DP';
    }

    this.id = data.id;
    this.product = data.productConfig.id;
    // this.type = data.contract.type;
    this.mode = getModality(data.productConfig, data.form, false);
    this.type = data.form.saludPlus ? data.form.saludPlus : 0;
    this.coverageRequestData = {
      saludPlus: data.form.saludPlus ? 'Si' : '',
    };

    this.infoLopd = find(data.policies, { id: 'infoLopd' })
      ? find(data.policies, { id: 'infoLopd' }).value
      : false;
    this.infoCesion = find(data.policies, { id: 'infoLopd' })
      ? find(data.policies, { id: 'infoLopd' }).value
      : false;

    this.paymentDetails = {
      Iban: `ES${
        data.paymentInfo.account
          ? data.paymentInfo.account.replace(/\s/g, '')
          : ''
      }`,
      Frequency: data.paymentInfo.paymentPeriod,
    };
    this.insureds = [];
     for (let i = 0; i < data.insureds.length; i += 1) {
      const coverages = {};
      if (
        data.productConfig.contract &&
        data.productConfig.contract.coverages
      ) {
        for (
          let j = 0;
          j < data.productConfig.contract.coverages.length;
          j += 1
        ) {
          const covFormName = data.productConfig.contract.coverages[j].formName;
          const covData = data.insureds[i].data[covFormName];
          coverages[covFormName] = covData ? 'Si' : 'No';
        }
      }
      const healthConditionData = data.insureds[i].healthConditionData;
      const keys = Object.keys(healthConditionData);
      const newHeathConditionData = [];
      let dataComments = '';
      for (let j = 0; j < keys.length; j += 1) {
        if (keys[j] !== 'comments') {
          newHeathConditionData[keys[j]] =
            healthConditionData[keys[j]] === 'true';
        } else {
          dataComments = healthConditionData[keys[j]];
        }
      }

      this.insureds.push(
        new Insured({
          ...data.insureds[i].data,

          healthCondition: newHeathConditionData,
          healthComments: dataComments,
          modality,
          ImportePrimeraPrima: importe,
          ImporteSubsidio: importeSubsidio,
          ImporteAsegurado: data.form.capital,
          Beneficiarios: data.benef ? data.benef.beneficiaries : undefined,
          coverages,
        }),
      );
    }
    this.comercialDetails = {
      id: '',
    };

    this.homeInfo = new HomeInfo(data);
    // TODO: Se queda deshabilitado para cuando se pueda configurar este campo por producto
    // this.effectDate = moment.utc(data.paymentInfo.policyStart, 'DD/MM/YYYY').toDate();
    const effectDate = data.paymentInfo.policyStart
      ? moment.utc(data.paymentInfo.policyStart, 'DD/MM/YYYY').toDate()
      : undefined;
    const date = moment().add(data.paymentInfo.startDatePostponedMonths, 'M');
    const momentDate = `01/${date.month() + 1}/${date.year()}`;
    this.effectDate =
      effectDate || moment.utc(momentDate, 'DD/MM/YYYY').toDate();
  }
}

export default class Contract {
  constructor(data) {
    this.PolicyHolder = new PersonData(data.policyHolder);
    this.Petitions = [];
    this.Petitions.push(
      new Petitions({
        id: data.id,
        paymentInfo: data.paymentInfo,
        productConfig: data.productConfig,
        form: data.form,
        insureds: data.insureds,
        homeInfo: data.homeInfo,
        policies: data.policies,
        benef: data.beneficiaries,
      }),
    );
  }
}
