import makeActionCreator from 'utils/makeActionCreator';
import {
  INITIALIZE_CONTRACT_ACTION,
  NEXT_STEP_ACTION,
  LAST_STEP_ACTION,
  VALIDATE_CONTRACT_ACTION,
  CONFIRMATE_CONTRACT_ACTION,
  RENEW_PIN_ACTION,
  CANCEL_CONFIRMATION_ACTION,
  RECALCULATE_ACTION,
  UPDATE_FEE_ACTION,
  CANCEL_LOAD_ACTION,
  SET_POLICY_ACTION,
  SET_POLICY_ERROR_ACTION,
  SEND_FILES_ACTION,
  SEND_DRAFT_ACTION,
  SET_CODE_ACTION,
} from './constants';

export const initializeContractAction = makeActionCreator(INITIALIZE_CONTRACT_ACTION, 'productConfig');
export const nextStepAction = makeActionCreator(NEXT_STEP_ACTION);
export const lastStepAction = makeActionCreator(LAST_STEP_ACTION);
export const validateContractAction = makeActionCreator(VALIDATE_CONTRACT_ACTION);
export const confirmateContractAction = makeActionCreator(CONFIRMATE_CONTRACT_ACTION);
export const renewPinAction = makeActionCreator(RENEW_PIN_ACTION, 'doc');
export const cancelConfirmationAction = makeActionCreator(CANCEL_CONFIRMATION_ACTION);
export const recalculateAction = makeActionCreator(RECALCULATE_ACTION, 'productConfig');
export const updateFeeAction = makeActionCreator(UPDATE_FEE_ACTION, 'newFee');
export const cancelLoadAction = makeActionCreator(CANCEL_LOAD_ACTION);
export const setPolicyAction = makeActionCreator(SET_POLICY_ACTION, 'id');
export const setPolicyErrorAction = makeActionCreator(SET_POLICY_ERROR_ACTION);
export const sendFilesAction = makeActionCreator(SEND_FILES_ACTION);
export const sendContractDraftAction = makeActionCreator(SEND_DRAFT_ACTION, 'email', 'onSend');
export const setCodeAction = makeActionCreator(SET_CODE_ACTION, 'code');

