import { fromJS } from 'immutable';
import {
  SHOW_RECOVERY_CONTRACT_MODAL_ACTION,
  HIDE_RECOVERY_CONTRACT_MODAL_ACTION,
  RECOVERY_ERROR_ACTION,
  RECOVERY_REQUIRES_NIF_ACTION,
  SET_LOADED_DRAFT_ACTION,
  PRELOAD_DRAFT_ACTION,
  LOAD_DRAFT_ACTION,
} from './constants';

const initialBaseState = {
  show: false,
  finished: false,
  error: false,
  message: undefined,
  requiresNif: false,
  isLoading: false,
  qparams: undefined,
  fromEmail: false,
};

const initialState = fromJS(initialBaseState);

const isLoadingByUrl = state => {
  return state.get('show') === false;
};

const setLoadingDraft = (state, action) => {
  const nextState = {
    isLoading: true,
  };
  if (isLoadingByUrl(state)) {
    nextState.fromEmail = true;
    nextState.show = true;
  }
  return state.merge({
    ...nextState,
  });
};

function recoveryContractModalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_RECOVERY_CONTRACT_MODAL_ACTION:
      return state.set('show', true);
    case PRELOAD_DRAFT_ACTION:
    case LOAD_DRAFT_ACTION:
      return setLoadingDraft(state, action);
    case HIDE_RECOVERY_CONTRACT_MODAL_ACTION:
      return state.merge(initialBaseState);
    case SET_LOADED_DRAFT_ACTION:
      return state.merge({
        finished: true,
        error: false,
        isLoading: false,
        message:
          'Se ha recuperado la solicitud correctamente. Puedes seguir con el proceso de contratación.',
      });
    case RECOVERY_ERROR_ACTION:
      return state.merge({
        finished: true,
        error: true,
        isLoading: false,
        message: action.error[0].description,
      });

    case RECOVERY_REQUIRES_NIF_ACTION: {
      return state.merge({
        requiresNif: true,
        isLoading: false,
        fromEmail: false,
        show: true,
        qparams: action.qparams,
      });
    }
    default:
      return state;
  }
}

export default recoveryContractModalReducer;
