import { createSelector } from 'reselect';

const selectDocAndSignatureViewDomain = () => state => state.get('docAndSignatureView').toJS();

const makeSelectShowModal = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.showModal,
);

const makeSelectAcceptedConditions = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.acceptedConditions,
);

const makeSelectCodes = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.codes,
);

const makeSelectPinIsLoading = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.pinIsLoading,
);

const makeSelectIdContract = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.idContract,
);

const makeSelectSendedPin = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.sendedPin,
);

const makeSelectPinIsSending = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.pinIsSending,
);

const makeSelectDocuments = () => createSelector(
  selectDocAndSignatureViewDomain(),
  substate => substate.documents,
);

const makeSelectButtonConditions = () => createSelector(
  selectDocAndSignatureViewDomain(),
  (substate) => substate.buttonConditions
);

export {
  makeSelectShowModal,
  makeSelectAcceptedConditions,
  makeSelectCodes,
  makeSelectPinIsLoading,
  makeSelectIdContract,
  makeSelectSendedPin,
  makeSelectPinIsSending,
  makeSelectDocuments,
  makeSelectButtonConditions,
};
