import 'whatwg-fetch';
import networkConfig from 'config/network.json';
import { formatQueryParams } from './functions';

const baseUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? networkConfig.production : networkConfig.stage;

export const getNet = (params) => new Promise((resolve, reject) => {
  let url = `${baseUrl}/${params.api}`;
  if (params.id) {
    url = `${url}/${params.id}`;
  }

  if (params.qparams) {
    const query = formatQueryParams(params.qparams);
    url = `${url}?${query}`;
  }

  const header = new Headers();
  header.set('Content-Type', 'text/plain; charset=utf-8');
  header.append('pragma', 'no-cache');
  header.append('cache-control', 'no-cache');
  if (params.header) {
    header.append('data', JSON.stringify(params.header));
  }

  fetch(url, {
    method: 'GET',
    headers: header,
  })
    .then((response) => (response.status === 204 ? '' : response.json()))
    .then((responseJson) => {      
      resolve(responseJson);
    })
    .catch((error) => {
      reject(error);
    });
});

export const postNet = (params) => new Promise((resolve, reject) => {
  fetch(`${baseUrl}/${params.api}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.body),
  })
    .then((response) => {
      if (response.status === 204) {
        resolve();
      } else {
        return response.json();
      }
    })
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      reject(error);
    });
});

export const putNet = (params) => new Promise((resolve, reject) => {
  fetch(`${baseUrl}/${params.api}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.body),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      reject(error);
    });
});

export const getWPPosts = (params) => new Promise((resolve, reject) => {
  
  fetch(`https://blog.divinaseguros.com/wp-json/wp/v2/posts?_embed&per_page=1`)
  .then((response) => (response.status === 204 ? '' : response.json()))
  .then((responseJson) => {    
    resolve(responseJson);
  })
  .catch((error) => {
    reject(error);
  });
});
