import {
  valDate,
  valrequired,
} from 'containers/ContractingPage/Forms/FormValidator';
import includes from 'lodash/includes';
import moment from 'moment';
import { fromJS } from 'immutable';

const MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * 5;

export const checkDate = (id, date, callback) => {
  let validationResult = valrequired(date);
  if (validationResult !== undefined) {
    callback(id, validationResult);
    return;
  }
  validationResult = valDate(date);
  if (validationResult !== undefined) {
    callback(id, validationResult);
    return;
  }
  if (moment(date, 'DD/MM/YYYY', true).isBefore()) {
    callback(id, 'La fecha de expiración no es válida');
    return;
  }

  callback(id, undefined);
};

const nifIsOld = (newNifs, savedNif) => newNifs.indexOf(savedNif) === -1;

export const deleteOldNifs = (newNifs, files, expirationDates) => {
  const savedNifsKeys = Object.keys(files.toJS());
  const filesJs = files.toJS();
  const expirationDatesJs = expirationDates.toJS();
  savedNifsKeys.forEach(savedNif => {
    if (nifIsOld(newNifs, savedNif)) {
      delete filesJs[savedNif];
      delete expirationDatesJs[savedNif];
    }
  });
  return {
    files: fromJS(filesJs),
    expirationDates: fromJS(expirationDatesJs),
  };
};

export const addNewNifs = (newNifs, files, expirationDates) => {
  let newFiles = files;
  let newExpirationDates = expirationDates;
  newNifs.forEach(nif => {
    if (!newFiles.get(`${nif}`)) {
      newFiles = newFiles.set([nif], []);
      newExpirationDates = expirationDates.set([nif], undefined);
    }
  });
  return { files: newFiles, expirationDates: newExpirationDates };
};

export const fileIsValid = file => {
  if (!(file.size < MAX_FILE_SIZE_IN_BYTES) || !includes(file.type, 'image/')) {
    alert('El archivo debe ser una imagen e inferior a 5MB');
    return false;
  }
  return true;
};

export default checkDate;
