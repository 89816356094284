import makeActionCreator from 'utils/makeActionCreator';
import {
  INITIALIZE_ACTION,
  SHOW_MODAL_ACTION,
  ACCEPT_CONDITIONS_ACTION,
  SET_CODE_ACTION,
  IS_VALID_CONTRACT_ACTION,
  RENEWED_PIN_ACTION,
  IS_PDF_RENDER,
  CANCEL_UPLOAD_ACTION,
} from './constants';

export const initializeAction = makeActionCreator(INITIALIZE_ACTION);
export const showModalAction = makeActionCreator(SHOW_MODAL_ACTION, 'value');
export const acceptConditionsAction = makeActionCreator(ACCEPT_CONDITIONS_ACTION);
export const setCodeAction = makeActionCreator(SET_CODE_ACTION, 'nif', 'code');
export const isValidContractAction = makeActionCreator(IS_VALID_CONTRACT_ACTION, 'idContract', 'documents');
export const renewedPinAction = makeActionCreator(RENEWED_PIN_ACTION);
export const isPdfRenderAction = makeActionCreator(IS_PDF_RENDER);
export const cancelUploadAction = makeActionCreator(CANCEL_UPLOAD_ACTION);
