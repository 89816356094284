import { fromJS } from 'immutable';
import { SET_FAQS_ACTION } from './constants';

const initialState = fromJS({});

function productPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FAQS_ACTION:
      
      return state.set('faqs', action.faqs);
    default:
      return state;
  }
}

export default productPageReducer;
