import { fromJS } from 'immutable';
import { SHOW_MODAL, FORM_SENDED } from './constants';

const initialState = fromJS({
  showModal: false,
  formSended: false,
});

function calculatorPageReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return state.merge({
        showModal: action.showModal,
        url: action.url,
        formSended: false,
      });
    case FORM_SENDED:
      return state.set('formSended', action.value);
    default:
      return state;
  }
}

export default calculatorPageReducer;
