import React from 'react';
import config from 'config.json';
import configLegal from 'config/legal.config.json';
import split from 'lodash/split';
import IconHelp from 'components/Icons/IconHelp';
import findKey from 'lodash/findKey';

export const getObjectPropertyByPath = (originalObj, path) => {
  try {
    return path
      .replace('[', '.')
      .replace(']', '')
      .split('.')
      .reduce((obj, property) => obj[property], originalObj);
  } catch (err) {
    return undefined;
  }
};

export const preventPropagation = (event) => {
  const ev = !event ? window.event : event; // IE:Moz
  ev.stopPropagation ? ev.stopPropagation() : (ev.cancelBubble = true); // eslint-disable-line
};

export const formatQueryParams = (qparams) => {
  const enc = encodeURIComponent;
  return Object.keys(qparams)
    .map((k) => {
      if (!qparams[k]) return undefined;
      return `${enc(k)}=${enc(qparams[k])}`;
    })
    .filter((x) => x)
    .join('&');
};

export const parseTooltip = (string) => {
  if (string && string.includes('<tp')) {
    const beforeSplitted = split(string, '<tp');
    const afterSplitted = split(beforeSplitted[1], '/tp>');
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: beforeSplitted[0] }} />
        <IconHelp
          tooltip={afterSplitted[0]}
          preventPropagation={preventPropagation}
        />
        <span dangerouslySetInnerHTML={{ __html: afterSplitted[1] }} />
      </div>
    );
  }
  return <span dangerouslySetInnerHTML={{ __html: string }} />;
};

export const getProductConfigById = (id) => {
  const key = findKey(config.products, {
    id: id.toString(),
  });
  return config.products[key];
};

export const tableGenerator = (rows) => {
  let table = '<table class="tooltip-table"><tbody>';
  for (let i = 0; i < rows.length; i += 1) {
    table += `<tr><td class="u-bold">${rows[i].left}</td><td>${rows[i].right}</td></tr>`;
  }
  table += '</tbody></table>';
  return table;
};

export const getPrivacyInfo = (product) => {
  if (product.enterprise === 'DP') {
    return configLegal.privacyDp;
  } else if (product.enterprise === 'DPSG') {
    return configLegal.privacyDpsg;
  }
  return '';
};
