import contractingConfig from 'config/contracting.config.json';
import includes from 'lodash/includes';
import { getObjectPropertyByPath } from 'utils/functions';
import find from 'lodash/find';

const getIPIDLinkLabel = (url) =>
  `, el <a href="${url}" target="_blank">documento de información previa sobre el producto de seguros (IPID)</a>,`;
const getInformativeNoteLinkLabel = (url) =>
  ` y la <a href="${url}" target="_blank">nota informativa previa</a>.`;

const getGenericInformativeNoteUrl = (productConfig, form) => {
  const documentsInModal = productConfig.productInfo.coverages.docModals;
  const documentsInLink = productConfig.productInfo.coverages.docLinks;

  if (documentsInLink) {
    for (let i = 0; i < documentsInLink.length; i += 1) {
      if (includes(documentsInLink[i].text, 'información legal')) {
        return documentsInLink[i].url;
      }
    }
  }
  if (documentsInModal) {
    const documentNames = [];
    let informativeNotesInModal = [];

    for (let i = 0; i < documentsInModal.length; i += 1) {
      if (includes(documentsInModal[i].title, 'Notas informativas')) {
        informativeNotesInModal = documentsInModal[i].files;
      }
    }
    for (let j = 0; j < informativeNotesInModal.length; j += 1) {
      documentNames.push(informativeNotesInModal[j].name);
    }

    const name = documentNames.find((a) =>
      a.includes(form.selectedModality.split(' ')[0]),
    );

    if (name) {
      const doc = find(informativeNotesInModal, { name });
      return doc.url;
    }
  }
  return '';
};

const getIPIDUrl = (productConfig, form) => {
  const documentsInModal = productConfig.productInfo.coverages.docModals;
  const documentsInLink = productConfig.productInfo.coverages.docLinks;

  if (documentsInLink) {
    for (let i = 0; i < documentsInLink.length; i += 1) {
      if (
        includes(
          documentsInLink[i].text,
          'Documento de Información sobre el Producto',
        )
      ) {
        return documentsInLink[i].url;
      }
    }
  }
  if (documentsInModal) {
    const documentNames = [];
    let informativeNotesInModal = [];

    for (let i = 0; i < documentsInModal.length; i += 1) {
      if (includes(documentsInModal[i].title, 'Notas informativas')) {
        informativeNotesInModal = documentsInModal[i].files;
      }
    }
    for (let j = 0; j < informativeNotesInModal.length; j += 1) {
      if (
        includes(
          informativeNotesInModal[j].name,
          'Documento de Información sobre el Producto',
        )
      ) {
        documentNames.push(informativeNotesInModal[j].name);
      }
    }
    const name = documentNames.find((a) =>
      a.includes(form.selectedModality.split(' ')[0]),
    );
    if (name) {
      const doc = find(informativeNotesInModal, { name });
      return doc.url;
    }
  }
  return '';
};

export const getInformativeNoteUrl = (productConfig, form) => {
  const addons = getObjectPropertyByPath(
    productConfig,
    'modalitiesInfo.addons',
  );

  // If products has addons then the docs are in the contracting.config.json
  // else the docs are in the coverages docs
  if (addons) {
    for (let i = 0; i < addons.length; i += 1) {
      const addon = addons[i];
      if (form[addon.id]) {
        const document = find(contractingConfig.documents, {
          name: `${form.selectedModality.split(' ')[0]} ${addon.id}`,
        });
        return document.url;
      }
      return getGenericInformativeNoteUrl(productConfig, form);
    }
  } else {
    return getGenericInformativeNoteUrl(productConfig, form);
  }
};

export default function getLabelWithDocs({
  id,
  label,
  productConfig,
  privacyDpsg,
  privacyDp,
  prevContractingDpsg,
  prevContractingDp,
  form,
}) {
  let formatedLabel = label;
  if (id === 'confirmation' && productConfig) {
    formatedLabel += `He leído y entendido la <a href="${
      productConfig.enterprise === 'DP'
        ? prevContractingDp
        : prevContractingDpsg
    }" target="_blank">información precontractual de los contratos a distancia</a>`;
    formatedLabel += `, la <a href="${
      productConfig.enterprise === 'DP' ? privacyDp : privacyDpsg
    }" target="_blank">política de privacidad</a>`;

    const ipdidUrl = getIPIDUrl(productConfig, form, formatedLabel);
    if (ipdidUrl) {
      formatedLabel += getIPIDLinkLabel(ipdidUrl);
    }

    const informativeNoteUrl = getInformativeNoteUrl(
      productConfig,
      form,
      formatedLabel,
    );
    if (informativeNoteUrl) {
      formatedLabel += getInformativeNoteLinkLabel(informativeNoteUrl);
    }

    formatedLabel += '<span class="u-font-size-normal u-font-bold">*</span>';

    return formatedLabel;
  }

  return formatedLabel;
}
