import makeActionCreator from 'utils/makeActionCreator';
import {
  INITIALIZE_FILTER_DATA_ACTION,
  SET_QUERY_PARAMS_ACTION,
  SET_FILTER_DATA_ACTION,
  SET_SORT_ACTION,
  SEARCH_ACTION,
  SET_SEARCH_SPECIALIST_ACTION,
  SET_SEARCH_LOCATION_ACTION,
  LOAD_MORE_ACTION,
  SET_IS_LOADING_SEARCH_ACTION,
  SET_IS_LOADING_MORE_ACTION,
  SET_SEARCH_RESULT_ACTION,
  SET_MORE_RESULTS_ACTION,
  SET_FORM_ACTION,
  SET_COORDS_ACTION,
} from './constants';


export const initializeFilterDataAction = makeActionCreator(INITIALIZE_FILTER_DATA_ACTION, 'callback', 'params');
export const setQueryParamsAction = makeActionCreator(SET_QUERY_PARAMS_ACTION, 'values');
export const setFilterDataAction = makeActionCreator(SET_FILTER_DATA_ACTION, 'filterDataMap');
export const setSortTypeAction = makeActionCreator(SET_SORT_ACTION, 'value');
export const searchAction = makeActionCreator(SEARCH_ACTION, 'values');
export const setSearchSpecialistAction = makeActionCreator(SET_SEARCH_SPECIALIST_ACTION, 'value');
export const setSearchLocationAction = makeActionCreator(SET_SEARCH_LOCATION_ACTION, 'value');
export const loadMoreAction = makeActionCreator(LOAD_MORE_ACTION);
export const setIsLoadingSearchAction = makeActionCreator(SET_IS_LOADING_SEARCH_ACTION, 'value');
export const setIsLoadingMoreAction = makeActionCreator(SET_IS_LOADING_MORE_ACTION, 'value');
export const setSearchResultAction = makeActionCreator(SET_SEARCH_RESULT_ACTION, 'response');
export const setMoreResultAction = makeActionCreator(SET_MORE_RESULTS_ACTION, 'response');
export const setFormAction = makeActionCreator(SET_FORM_ACTION, 'value');
export const setCoordsAction = makeActionCreator(SET_COORDS_ACTION, 'value');

