import { createSelector } from 'reselect';

const selectNifUploaderDomain = () => state =>
  state.get('nifUploader').toJS();

const makeSelectFilesUploaded = () =>
  createSelector(selectNifUploaderDomain(), substate => substate.files);

const makeSelectExpirationDates = () =>
  createSelector(
    selectNifUploaderDomain(),
    substate => substate.expirationDates,
  );

const makeSelectExpirationDatesErrors = () =>
  createSelector(
    selectNifUploaderDomain(),
    substate => substate.expirationDatesErrors,
  );

const makeSelectDocumentsId = () =>
  createSelector(selectNifUploaderDomain(), substate => substate.documentsId);

export {
  makeSelectFilesUploaded,
  makeSelectExpirationDatesErrors,
  makeSelectExpirationDates,
  makeSelectDocumentsId,
};
