import { fromJS } from 'immutable';
import {
  SELECT_OPTION_ACTION,
  ACTIVE_MENU_ACTION,
} from './constants';

const initialState = fromJS({
  selectedOption: undefined,
  isActive: false,
});

function menuReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_OPTION_ACTION:
      if (state.get('selectedOption') === action.position) {
        return state.set('selectedOption', undefined);
      }
      return state.set('selectedOption', action.position);
    case ACTIVE_MENU_ACTION: // eslint-disable-line
      const nextValue = !state.get('isActive');
      if (!nextValue) {
        return state.merge({
          isActive: nextValue,
          selectedOption: undefined,
        });
      }
      return state.set('isActive', nextValue);
    default:
      return state;
  }
}

export default menuReducer;
