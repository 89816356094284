import { fromJS } from 'immutable';
import { CHANGE_TAB } from './constants';

const initialState = fromJS({
  activeTab: 1,
});

function TabsReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_TAB:
      return state.set('activeTab', action.index);
    default:
      return state;
  }
}

export default TabsReducer;
