/*
 *
 * Faqs reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SELECT_FAQ,
} from './constants';

const initialState = fromJS({
  selectedFaq: -1,
});

function faqsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FAQ:
      if (action.index === state.get('selectedFaq')) {
        return state.set('selectedFaq', -1);
      }
      return state.set('selectedFaq', action.index);
    default:
      return state;
  }
}

export default faqsReducer;
