import { call, put, select, takeLatest } from 'redux-saga/effects';
import findIndex from 'lodash/findIndex';
import { getNet } from 'utils/networking';
import { getBirthDateFromAge, getAge } from 'utils/dateFactory';
import { makeSelectForm } from 'containers/CalculatorPage/selectors';
import { makeSelectInsureds } from '../InsuredsView/selectors';
import { makeSelectHomeData } from '../HomeView/selectors';
import getCalculatorRequest from '../calculatorRequest';
import { INITIALIZE_CONTRACT_ACTION, RECALCULATE_ACTION } from '../constants';
import { updateFeeAction } from '../actions';
import { getMensualFee, getModalityPrice } from '../functions';
import { makeSelectProductConfig } from '../selectors';


const calculatorIsForm = productConfig =>
  productConfig.calculator && productConfig.calculator.type === 'form';
const insuredHasField = (insureds, field) =>
  insureds[0].data[field] !== undefined;
const insuredsHasAges = insureds => {
  let hasAges = true;

  insureds.forEach(insured => {
    if (insured.data.birthDate === undefined) {
      hasAges = false;
    }
  });

  return hasAges;
};


const setFormFromInsureds = (productConfig, form, insureds) => {
  const nextForm = form;
  for (let i = 0; i < productConfig.calculator.form.length; i += 1) {
    const calcForm = productConfig.calculator.form[i];
    if (calcForm.formName === 'age' && insuredHasField(insureds, 'birthDate')) {
      nextForm.age = getAge(insureds[0].data.birthDate);
    } else if (
      calcForm.formName === 'postalCode' &&
      insuredHasField(insureds, 'postalCode')
    ) {
      nextForm.postalCode = insureds[0].data.postalCode;
    } else if (calcForm.type === 'ages-selector' && insuredsHasAges(insureds)) {
      nextForm.personNumberSelected = insureds.length;
      for (let j = 0; j < insureds.length; j += 1) {
        nextForm[`age${j}`] = getAge(insureds[j].data.birthDate);
      }
    }
  }
  return nextForm;
};

export function* recalculate(action) {
  try {
    const productConfig = action.productConfig
      ? action.productConfig
      : yield select(makeSelectProductConfig());

    if (calculatorIsForm(productConfig)) {
      let form = yield select(makeSelectForm());
      const insureds = yield select(makeSelectInsureds());

      form = setFormFromInsureds(productConfig, form, insureds);

      let requestList = getCalculatorRequest(productConfig, form, insureds);
      const personInfo = { BirthDate: getBirthDateFromAge(form.age) };

      if (productConfig.id === '5') {
        // Hogar special case
        const homeInfo = yield select(makeSelectHomeData());
        requestList = [
          {
            type: productConfig.id,
            subtype: form.selectedProduct,
            params: {
              ...form,
              risk: form.risk ? 1 : -1,
              Jewels: homeInfo.lowJewelry
                ? (Number(homeInfo.lowJewelry) * 100).toString()
                : '0',
              JewelsOutSafe: homeInfo.highOutJewelry
                ? (Number(homeInfo.highOutJewelry) * 100).toString()
                : '0',
              JewelsInSafe: homeInfo.highInJewelry
                ? (Number(homeInfo.highInJewelry) * 100).toString()
                : '0',
              Objects: homeInfo.lowObjects
                ? (Number(homeInfo.lowObjects) * 100).toString()
                : '0',
              ObjectsOutSafe: homeInfo.highOutObjects
                ? (Number(homeInfo.highOutObjects) * 100).toString()
                : '0',
              ObjectsInSafe: homeInfo.highInObjects
                ? (Number(homeInfo.highInObjects) * 100).toString()
                : '0',
            },
          },
        ];
        if (form.selectedProduct === '4') {
          productConfig.modalitiesInfo.modalitiesGroup.splice(1, 1);
        } else {
          productConfig.modalitiesInfo.modalitiesGroup.splice(0, 1);
        }
      }
      const response = yield call(getNet, {
        api: 'api/calculate',
        header: { requestList, personInfo },
        id: '',
      });
      const price = getModalityPrice({
        productConfig,
        response,
        modGrpIndex: form.selectedModalityGrp,
        modIndex: findIndex(
          productConfig.modalitiesInfo.modalitiesGroup[form.selectedModalityGrp]
            .modalities,
          { modalityName: form.selectedModality },
        ),
      });
      form.selectedModalityTotalPrice = price;
      const mensualFee = getMensualFee(productConfig, form, response);
      yield put(updateFeeAction(mensualFee));
    } else {
      const form = yield select(makeSelectForm());
      const insureds = yield select(makeSelectInsureds());
      let fee = form.fee;
      if (!fee && form.capital) {
        fee = form.capital;
      }
      yield put(updateFeeAction(fee * insureds.length));
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* defaultSaga() {
  yield takeLatest(INITIALIZE_CONTRACT_ACTION, recalculate);
  yield takeLatest(RECALCULATE_ACTION, recalculate);
}
