import { fromJS } from 'immutable';
import {
  SET_RESPONSE,
  FILTER_OFFICES,
  SET_OFFICE_DISTANCE,
  SET_COMBO_OPTIONS,
  SELECT_OFFICE,
  TOGGLE_MAP,
  SET_USER_COORDS,
} from './constants';
import { comparerOfficesByDistance,
} from './functions';

const initialState = fromJS({
  officesByLocation: [],
  offices: [],
  options: [],
  showMap: false,
  userCoords: {},
});

function officeSearcherReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESPONSE:
      return state.merge({
        response: action.response,
        offices: action.response,
      });
    case SET_COMBO_OPTIONS:
      return state.set('options', action.options);
    case FILTER_OFFICES:
      return state.merge({
        officesByLocation: action.offices.sort(comparerOfficesByDistance),
        officeSelected: action.offices[0],
      });
    case SET_OFFICE_DISTANCE:
      return state.set('offices', action.offices);
    case SELECT_OFFICE:
      return state.set('officeSelected', action.officeSelected);
    case TOGGLE_MAP:
      return state.set('showMap', !state.get('showMap'));
    case SET_USER_COORDS:
      return state.set('userCoords', action.coords);
    default:
      return state;
  }
}

export default officeSearcherReducer;
