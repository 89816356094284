import { call, put, takeLatest } from 'redux-saga/effects';
import { getNet } from 'utils/networking';
import { LOAD_OFFICES } from './constants';
import { setResponseAction } from './actions';

function* loadOffices() {
  const response = yield call(getNet, { api: 'api/offices', id: '' });
  yield put(setResponseAction(response));
}

export function* defaultSaga() {
  yield takeLatest(LOAD_OFFICES, loadOffices);
}

// All sagas to be loaded
export default [
  defaultSaga,
];
