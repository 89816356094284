/**
 PRIVATE METHODS
 */

function buildProductList(productJson) {
  const productList = [];
  for (let i = 0, len = productJson.length; i < len; i += 1) {
    const item = {
      value: productJson[i].type,
      name: productJson[i].name,
      specialitiesList: buildSpecialities(productJson[i].specialities, [], ''),
    };
    productList.push(item);
  }
  return productList;
}

function buildSpecialities(specialitiesJson, specialitiesList, indent) {
  if (specialitiesJson === null || specialitiesJson === undefined)
    return specialitiesList;

  for (let i = 0, len = specialitiesJson.length; i < len; i += 1) {
    const item = {
      value: specialitiesJson[i].id,
      name: `${indent}${specialitiesJson[i].name}`,
    };
    specialitiesList.push(item);
    buildSpecialities(
      specialitiesJson[i].child,
      specialitiesList,
      `${indent}     `,
    );
  }
  return specialitiesList;
}

function buildFilterDataMap(dataFilter) {
  const map = {};
  if (dataFilter !== undefined) {
    for (let i = 0, len = dataFilter.length; i < len; i += 1) {
      const data = buildProductList(dataFilter[i].types);
      map[dataFilter[i].type] = data;
    }
  }
  return map;
}

function getProductList(filterDataMap, medicalTeamId) {
  return filterDataMap && medicalTeamId && filterDataMap[medicalTeamId]
    ? filterDataMap[medicalTeamId]
    : [];
}

function getSpecialistList(filterDataMap, medicalTeamId, productId) {
  return filterDataMap &&
    medicalTeamId &&
    productId &&
    filterDataMap[medicalTeamId]
    ? filterDataMap[medicalTeamId][
        getIndexFromArray(filterDataMap[medicalTeamId], productId)
      ].specialitiesList
    : [];
}

function getIndexFromArray(array, value) {
  if (array && value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].value == value) {
        return i;
      }
    }
  }
  return 0;
}

function getInitialMedicalTeam(filterDataMap, qparams, medicalTeamOptions) {
  if (qparams && qparams.team) {
    if (filterDataMap) {
      return filterDataMap[qparams.team]
        ? qparams.team
        : medicalTeamOptions[0].id;
    }

    for (let i = 0; i < medicalTeamOptions.length; i += 1) {
      if (qparams.team == medicalTeamOptions[i].id) {
        return medicalTeamOptions[i].id;
      }
    }
  }
  return medicalTeamOptions[0].id;
}

function buildGeoData(results) {
  const geoData = {};
  if (results && results[0]) {
    const addrComponents = results[0].address_components;
    for (let i = 0; i < addrComponents.length; i += 1) {
      const item = addrComponents[i];
      switch (item.types[0]) {
        case 'postal_code':
          geoData.postalCode = item.long_name;
          break;

        case 'locality':
          geoData.town = item.long_name;
          break;

        case 'administrative_area_level_1':
          if (!geoData.province) {
            geoData.province = item.long_name;
          }
          break;

        case 'administrative_area_level_2':
          geoData.province = item.long_name;
          break;

        case 'administrative_area_level_4':
          if (!geoData.town) {
            geoData.town = item.long_name;
          }
          break;

        default:
          break;
      }
    }

    if (results[0].geometry && results[0].geometry.viewport) {
      geoData.southWestLatitude = results[0].geometry.viewport
        .getSouthWest()
        .lat();
      geoData.southWestLongitude = results[0].geometry.viewport
        .getSouthWest()
        .lng();
      geoData.northEastLatitude = results[0].geometry.viewport
        .getNorthEast()
        .lat();
      geoData.northEastLongitude = results[0].geometry.viewport
        .getNorthEast()
        .lng();
    }
  }
  return geoData;
}

export {
  buildFilterDataMap,
  getIndexFromArray,
  getProductList,
  getSpecialistList,
  getInitialMedicalTeam,
  buildGeoData,
};
