import { fromJS } from 'immutable';
import {
  SHOW_VIDEO_ACTION,
} from './constants';

const initialState = fromJS({
  showVideo: false,
});

function contractingPageReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_VIDEO_ACTION:
      return state.set('showVideo', !state.get('showVideo'));
    default:
      return state;
  }
}

export default contractingPageReducer;
