import React from 'react';
import { initSbjs } from 'utils/cookies';
import configCookie from '../../config/config.cookie.json';

const config = configCookie;
const parrafoNodeName = 'P';

const modifyElement = (tag, configSection) => {
  const contentElement = document.querySelector(
    `.cc-cp-body-content-entry[content_layout="${tag}"]`,
  );
  const check = contentElement.lastChild.cloneNode(true);
  contentElement.innerHTML = config[configSection];
  if (check.nodeName !== parrafoNodeName) {
    contentElement.append(check);
  }
};

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (window.cookieconsent) {
      window.cookieconsent.run({
        notice_banner_type: 'simple',
        consent_type: 'express',
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: true,
        palette: 'light',
        language: 'es',
        website_name: 'divinaseguros.com',
        open_preferences_center_selector: '.PoliticaCookies',
        cookies_policy_url:
          'https://www.divinaseguros.com/corporativo/politica-de-cookies',
        callbacks: {
          notice_banner_loaded: () => {
            console.log(
              '>>>>>>> called notice_banner_loaded callback from dist/index.html',
            );
            // const cookieLink = document.getElementsByClassName(
            //   'PoliticaCookiesBanner',
            // );

            // if (cookieLink.length > 0) {
            //   for (var i = 0; i < cookieLink.length; i++) {
            //     cookieLink[i].addEventListener('click', () => {
            //       this.configurarPanelCookies();
            //     });
            //   }
            // }
          },
        },
      });
    }

    let element = document.getElementsByClassName('cc-nb-text-container')[0];

    if (element) {
      element.innerHTML = config.mensaje;
      element = document.getElementsByClassName('cc-nb-okagree')[0];
      element.textContent = config.aceptar;
      element.addEventListener('click', () => {
        initSbjs();
      });
      element = document.getElementsByClassName('PoliticaCookiesBanner')[0];
      if (element) {
        element.addEventListener('click', () => {
          window.cookieconsent.openPreferencesCenter();
          this.configurarPanelCookies();
        });
      }
    }

    element = document.getElementsByClassName('cc-nb-changep')[0];
    if (element) {
      element.textContent = config.preferencias;
      element.addEventListener('click', () => {
        this.configurarPanelCookies();
      });
    }
  }

  configurarPanelCookies = () => {
    var item = document.querySelector('button[t="content_functionality"]');
    if (item) {
      item.remove();
    }

    var languageSelect = document.getElementsByClassName(
      'cc-pc-head-lang-select',
    );
    if (languageSelect.length > 0) {
      languageSelect[0].remove();
    }

    document.querySelector(
      'button[t="content_strictly-necessary"]',
    ).textContent = 'Cookies técnicas (necesarias)';

    document.querySelector('button[t="content_tracking"]').textContent =
      'Cookies de análisis y medición';
    document.querySelector('button[t="content_targeting"]').textContent =
      'Cookies de publicidad comportamental';

    modifyElement('content_your_privacy', 'privacidad');
    modifyElement('content_strictly-necessary', 'estrictas');
    modifyElement('content_tracking', 'rastreoRendimiento');
    modifyElement('content_targeting', 'seguimientoPublicidad');
    modifyElement('content_more_information', 'masInformacion');
    var footer = document.getElementsByClassName('cc-cp-foot-byline')[0];
    footer.textContent =
      'Al pulsar “Guardar configuración”, se guardará la selección de cookies que hayas realizado. Si no has seleccionado ninguna opción, pulsar este botón equivaldrá  a rechazar todas las cookies.';

    const saveButton = document.getElementsByClassName('cc-cp-foot-save')[0];
    saveButton.lastChild.textContent = 'Guardar configuración';
    saveButton.addEventListener('click', () => {
      initSbjs();
    });
  };

  render() {
    return null;
  }
}

export default CookieConsent;
