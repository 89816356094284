import React from 'react';
import PropTypes from 'prop-types';

export const renderFieldText = ({
  input,
  type,
  placeholder,
  formInputClassName,
  meta: { touched, error },
}) => (
  <div className={`form-field ${touched && error ? 'has-error' : ''}`}>
    <div className="form-control u-margin-bottom-small">
      <input
        {...input}
        type={type}
        className={`form-input ${formInputClassName}`}
        placeholder={placeholder || ''}
      />
    </div>
    {touched && error && <p className="help-block">{error}</p>}
  </div>
);

renderFieldText.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  formInputClassName: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

export default renderFieldText;
