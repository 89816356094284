import { fromJS } from 'immutable';
import { deleteOldNifs, addNewNifs } from './functions';
import {
  INITIALIZE_ACTION,
  UPLOAD_FILE_ACTION,
  DELETE_FILE_ACTION,
  UPDATE_EXPIRATION_DATE,
  UPDATE_ERROR_EXPIRATION_DATE,
} from './constants';
import {
  SET_FILES_FROM_DRAFT_ACTION,
  SET_FILES_SENDED_ACTION,
} from '../constants';

const initialState = fromJS({
  files: {},
  documentsId: {},
  expirationDates: {},
  expirationDatesErrors: {},
});

const intializeUploaders = (state, action) => {
  let { files, expirationDates } = deleteOldNifs(
    action.nifs,
    state.get('files'),
    state.get('expirationDates'),
  );

  const newData = addNewNifs(action.nifs, files, expirationDates);
  files = newData.files;
  expirationDates = newData.expirationDates;

  return state.merge({ files, expirationDates });
};

const setUploadedFiles = (state, action) => {
  const resultFiles = {};
  const resultDocumentsId = {};
  action.files.forEach(file => {
    resultFiles[file.nif] = [{ name: 'DNI GUARDADO', fromDraft: true }];
    resultDocumentsId[file.nif] = file.documentId;
  });
  return state.merge({
    files: fromJS(resultFiles),
    documentsId: fromJS(resultDocumentsId),
  });
};

const setFilesSended = (state, action) => {
  const resultDocumentsId = {};
  action.files.forEach(file => {
    if (file.nif) {
      resultDocumentsId[file.nif] = file.documentId;
    }
  });
  return state.set('documentsId', resultDocumentsId);
};

function nifUploaderReducer(state = initialState, action) {
  const stateJs = state.toJS();
  switch (action.type) {
    case INITIALIZE_ACTION:
      return intializeUploaders(state, action);
    case UPDATE_EXPIRATION_DATE:
      stateJs.expirationDates[action.id] = action.date;
      return fromJS(stateJs);
    case UPDATE_ERROR_EXPIRATION_DATE:
      stateJs.expirationDatesErrors[action.id] = action.error;
      return fromJS(stateJs);
    case UPLOAD_FILE_ACTION:
      if (!stateJs.files[action.id].length) {
        stateJs.expirationDates[action.id] = undefined;
        stateJs.expirationDatesErrors[action.id] = undefined;
      }
      stateJs.files[action.id].push(action.file);
      return fromJS(stateJs);
    case SET_FILES_FROM_DRAFT_ACTION:
      return setUploadedFiles(state, action);
    case DELETE_FILE_ACTION:
      stateJs.files[action.id].splice(action.index, 1);
      stateJs.expirationDates[action.id] = undefined;
      stateJs.expirationDatesErrors[action.id] = undefined;
      return fromJS(stateJs);
    case SET_FILES_SENDED_ACTION:
      return setFilesSended(state, action);
    default:
      return state;
  }
}

export default nifUploaderReducer;
