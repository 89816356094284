import { createSelector } from 'reselect';

const selectPolicyHolderViewDomain = () => (state) => state.get('policyHolderView').toJS();

const makeSelectPolicyHolderData = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.data
);

const makeSelectPolicyHolderFromInsured = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.isPolicyHolderAnInsured
);

const makeSelectIsEditing = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.isEditing
);

const makeSelectPaymentInfo = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.paymentInfo
);

const makeSelectIsEditingPaymentInfo = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.isEditingPaymentInfo
);

const makeSelectBeneficiaries = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.beneficiariesInfo
);

const makeSelectIsEditingBeneficiaries = () => createSelector(
  selectPolicyHolderViewDomain(),
  (substate) => substate.isEditingBeneficiaries
);

export {
  makeSelectPolicyHolderData,
  makeSelectPolicyHolderFromInsured,
  makeSelectIsEditing,
  makeSelectPaymentInfo,
  makeSelectIsEditingPaymentInfo,
  makeSelectBeneficiaries,
  makeSelectIsEditingBeneficiaries,
};
