/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form/immutable';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import medicalTeamPageReducer from 'containers/MedicalTeamPage/reducer';
import faqsReducer from 'containers/Faqs/reducer';
import productPageReducer from 'containers/ProductPage/reducer';
import menuReducer from 'containers/Menu/reducer';
import calculatorReducer from 'containers/CalculatorPage/reducer';
import tabsReducer from 'containers/Tabs/reducer';
import officeSearcher from 'containers/OfficeSearcher/reducer';
import callRequestBox from 'containers/CallRequestBox/reducer';
import insuredsViewReducer from 'containers/ContractingPage/InsuredsView/reducer';
import policyHolderViewReducer from 'containers/ContractingPage/PolicyHolderView/reducer';
import docAndSignatureViewReducer from 'containers/ContractingPage/DocAndSignatureView/reducer';
import nifUploaderReducer from 'containers/FilesUploader/NifUploader/reducer';
import bankStatementUploaderReducer from 'containers/FilesUploader/BankStatementUploader/reducer';
import homeViewReducer from 'containers/ContractingPage/HomeView/reducer';
import newsViewerReducer from 'containers/NewsViewer/reducer';
import productSearchReducer from 'containers/ProductSearch/reducer';
import homePageReducer from 'containers/HomePage/reducer';
import recoveryContractModalReducer from 'containers/RecoveryContractModal/reducer';
import contractingPageReducer from 'containers/ContractingPage/reducer';
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    form: formReducer,
    route: routeReducer,
    language: languageProviderReducer,
    medicalTeamPage: medicalTeamPageReducer,
    faqsState: faqsReducer,
    productPageState: productPageReducer,
    calculatorPage: calculatorReducer,
    menuState: menuReducer,
    tabsComponent: tabsReducer,
    officeSearcher,
    nifUploader: nifUploaderReducer,
    bankStatementUploader: bankStatementUploaderReducer,
    callRequestBox,
    insuredsView: insuredsViewReducer,
    policyHolderView: policyHolderViewReducer,
    docAndSignatureView: docAndSignatureViewReducer,
    homeView: homeViewReducer,
    newsViewer: newsViewerReducer,
    productSearch: productSearchReducer,    
    homePage: homePageReducer,
    recoveryContractModal: recoveryContractModalReducer,
    contractingPage: contractingPageReducer,
    ...asyncReducers,
  });
}
