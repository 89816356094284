import { postNet } from 'utils/networking';
import { browserHistory } from 'react-router';

const getUtmSource = () => {
  if (window.ga && window.ga.getAll) {
    const trackers = window.ga.getAll();
    if (trackers && trackers.length > 0) {
      const url = trackers[0].get('location');
      const queryParam = 'utm_source';
      if (url) {
        const initialPosition = url.search(queryParam);
        if (initialPosition > -1) {
          const urlFinal = url.substring(
            initialPosition + queryParam.length + 1,
            url.length,
          );
          if (urlFinal && urlFinal.length > 0) {
            const finalPosition = urlFinal.search('&');
            if (finalPosition > -1) {
              return urlFinal.substring(0, finalPosition);
            }
            return urlFinal.substring(0, urlFinal.length);
          }
        }
      }
    }
  }
  return '@Web';
};

export const onSubmit = (event, onSended, button) => {
  const formData = event.toJS();
  const location = browserHistory.getCurrentLocation();
  const nameSplitted = formData.name.split(' ');
  button.disabled = true;

  // Transform to UpperCamelCase
  for (let i = 0; i < nameSplitted.length; i += 1) {
    nameSplitted[i] =
      nameSplitted[i].charAt(0).toUpperCase() + nameSplitted[i].slice(1);
  }
  const nameUpperCamelCase = nameSplitted.join(' ');
  const productSplitted = location.pathname.split('/');
  const product = productSplitted[productSplitted.length - 1];
  var phone = formData.phone;
  console.log(phone);
  // Remove all spaces
  phone = phone.replace(/ /g, '');
  // If string starts with +34, drop first 3 characters
  if (phone.startsWith('+34')) {
    phone = phone.substring(3);
  }

  console.log(phone);

  const utm = getUtmSource();

  const goContactData = {
    database_id: 1112,
    first_phone: formData.phone,
    callback: false,
    contact_name: nameUpperCamelCase,
    address: '',
    city: '',
    country: '',
    postal_code: '',
    email: '',
    field3: '@Web', //formId
    field4: '', //dni
    field5: '', //provincia
    field6: '', //sexo
    field7: '', //fecha nacimiento
    field8: product, //observaciones
    field9: utm, //provedorAds
    field10: '', //idSession
    field11: location.pathname, //url
    token: '',
    direct_to_hopper: true,
  };
  const requestModel = {
    data: { name: nameUpperCamelCase, phone: phone },
    source: 'Formulario Web Divina Pastora',
    page: location.pathname,
    product,
    adsSupplier: utm,
    goContactData,
  };
  const buttonStyle = button.className;
  button.className += ' is-loading'; // eslint-disable-line no-param-reassign
  postNet({ api: 'api/Lead', body: requestModel })
    .then(() => {
      onSended();
      button.className = buttonStyle; // eslint-disable-line no-param-reassign
    })
    .catch((error) => {
      console.log(error);
      button.disabled = false;
      button.className = buttonStyle; // eslint-disable-line no-param-reassign
      alert('Se ha producido un error en la conexión, vuelva a intentarlo');
    });
};
