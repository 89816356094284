import 'core-js/es5';
import 'core-js/es6';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import './assets/css/styles.css';

// CSS from styles npm package
// import 'styles/dist/css/styles.css';

// Import root app
import App from './containers/App';

// Import selector for `syncHistoryWithStore`
import { makeSelectLocationState } from './containers/App/selectors';

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider';

// Inits sbjs if tracking cookie is enabled
import { initSbjs } from 'utils/cookies';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
//import '!file-loader?name=[name].[ext]!/public/favicon.ico';
//import '!file-loader?name=[name].[ext]!/public/manifest.json';
//import 'file-loader?name=[name].[ext]!/public/.htaccess';
//import 'file-loader?name=[name].[ext]!/public/robots.txt';
//import 'file-loader?name=[name].[ext]!/public/sitemap.xml';
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './store';

// Import i18n messages
import { translationMessages } from './i18n';

// Import root routes
import createRoutes from './routes';

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
export const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
  component: App,
  childRoutes: createRoutes(store),
};

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const logGoogleAnalyticsPageview = () => {
  if (isProduction) {
    if (!window.ga) {
      window.onload = () => {
        window.ga('gtm1.require', 'ec');
        window.ga(
          'gtm1.send',
          'pageview',
          window.location.pathname + window.location.search,
        );
      };
    } else {
      window.ga(
        'gtm1.send',
        'pageview',
        window.location.pathname + window.location.search,
      );
    }
  }
};

initSbjs();

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <Router
          onUpdate={() => {
            window.scrollTo(0, 0);
            logGoogleAnalyticsPageview();
          }}
          history={history}
          routes={rootRoute}
        />
      </LanguageProvider>
    </Provider>,
    document.getElementById('app'),
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept('./i18n', () => {
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
