import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { createStructuredSelector } from 'reselect';
import ModalBasic from 'components/ModalBasic';
import ResponseMessage from 'components/ResponseMessage';
import RecoveryForm from './RecoveryForm';
import {
  hideRecoveryContractModalAction,
  preLoadContractDraftAction,
  loadContractDraftAction,
} from './actions';
import addToQuery from './functions';
import {
  makeSelectShow,
  makeSelectError,
  makeSelectFinished,
  makeSelectMessage,
  makeSelectRequiresNif,
  makeSelectisLoading,
  makeSelectQparams,
  makeSelectFromEmail,
} from './selectors';

export class RecoveryContractModal extends React.PureComponent {
  componentDidMount() {
    const location = this.props.location;
    const code = location.query.code;
    const key = location.query.key;
    if (key && code) {
      this.props.preLoadDraft({ key, code });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.location.query, nextProps.location.query)) {
      const location = nextProps.location;
      const code = location.query.code;
      const key = location.query.key;
      if (key && code) {
        this.props.preLoadDraft({ key, code });
      }
    }
  }

  onSubmit = values => {
    if (!this.props.requiresNif) {
      addToQuery(values);
    } else {
      this.props.loadDraft({
        ...this.props.qparams.toJS(),
        nif: values.get('nif'),
      });
    }
  };

  render() {
    return (
      <ModalBasic
        isActive={this.props.showModal}
        modalContentStyle={{ maxWidth: '22rem' }}
        onClose={this.props.onHideModal}
      >
        <h3 className="u-font-h4 u-text-center u-margin-bottom-small">
          Recupera tu solicitud
        </h3>
        {this.props.finished ? (
          <ResponseMessage
            message={this.props.message}
            onBtnClick={this.props.onHideModal}
            error={this.props.error}
            isLoading={this.props.isLoading}
          />
        ) : !this.props.fromEmail ? (
          <RecoveryForm
            onSubmit={this.onSubmit}
            isLoading={this.props.isLoading}
            requiresNif={this.props.requiresNif}
          />
        ) : (
          <div className="spinner" style={{ maxWidth: 450, height: 150 }} />
        )}
      </ModalBasic>
    );
  }
}

RecoveryContractModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  requiresNif: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  preLoadDraft: PropTypes.func.isRequired,
  loadDraft: PropTypes.func.isRequired,
  message: PropTypes.string,
  location: PropTypes.object.isRequired,
  qparams: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  showModal: makeSelectShow(),
  error: makeSelectError(),
  requiresNif: makeSelectRequiresNif(),
  finished: makeSelectFinished(),
  message: makeSelectMessage(),
  isLoading: makeSelectisLoading(),
  qparams: makeSelectQparams(),
  fromEmail: makeSelectFromEmail(),
});

function mapDispatchToProps(dispatch) {
  return {
    onHideModal: () => {
      dispatch(hideRecoveryContractModalAction());
    },
    preLoadDraft: qparams => dispatch(preLoadContractDraftAction(qparams)),
    loadDraft: qparams => dispatch(loadContractDraftAction(qparams)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  RecoveryContractModal,
);
