import { fromJS } from 'immutable';
import find from 'lodash/find';
import contractingConfig from 'config/contracting.config.json';
import findIndex from 'lodash/findIndex';
import { SET_LOADED_DRAFT_ACTION } from 'containers/RecoveryContractModal/constants';
import { getObjectPropertyByPath, getProductConfigById } from 'utils/functions';
import {
  INITIALIZE_CONTRACT_ACTION,
  NEXT_STEP_ACTION,
  LAST_STEP_ACTION,
  VALIDATE_CONTRACT_ACTION,
  CANCEL_CONFIRMATION_ACTION,
  UPDATE_FEE_ACTION,
  CONFIRMATE_CONTRACT_ACTION,
  CANCEL_LOAD_ACTION,
  SET_POLICY_ACTION,
  SET_POLICY_ERROR_ACTION,
  SET_CODE_ACTION,
} from './constants';

const initialState = fromJS({
  fee: 0,
  step: 0,
  policies: [],
  policiesError: [],
  promoterNif: undefined,
});

const setLoadedDraft = (state, action) => {
  const infoCesion = getObjectPropertyByPath(
    action.contract,
    'petitions[0].infoCesion',
  );
  const infoLopd = getObjectPropertyByPath(
    action.contract,
    'petitions[0].infoLOPD',
  );
  const comercialDetails = getObjectPropertyByPath(
    action.contract,
    'petitions[0].comercialDetails',
  );
  const draftProduct = action.contract.petitions[0].product;
  const productConfig = getProductConfigById(draftProduct);

  return state.merge({
    policies: [
      { id: 'infoCesion', value: infoCesion },
      { id: 'infoLopd', value: infoLopd },
    ],
    productConfig,
    promoterNif: comercialDetails ? comercialDetails.nifPromoter : undefined,
    code: action.code,
    key: action.key,
    allowNie: action.contract.allowNie,
    step: 0,
  });
};

const setPolicy = (state, action) => {
  const actualPolicies = state.get('policies').toJS();
  const policiesError = state.get('policiesError').toJS();
  const policy = find(actualPolicies, { id: action.id });

  // Check the error and delete
  const errorIndex = policiesError.indexOf(action.id);
  if (errorIndex !== -1) {
    policiesError.splice(errorIndex, 1);
  }

  // Initialize
  if (policy === undefined) {
    actualPolicies.push({ id: action.id, value: true });
  } else {
    policy.value = !policy.value;
    actualPolicies[findIndex(actualPolicies, { id: action.id })] = policy;
  }

  return state.merge({
    policies: fromJS(actualPolicies),
    policiesError: fromJS(policiesError),
  });
};

const setPolicyError = state => {
  const policyConfig = contractingConfig.policy;
  const errors = [];
  for (let i = 0; i < policyConfig.length; i += 1) {
    const pol = find(state.get('policies').toJS(), {
      id: policyConfig[i].id,
    });
    if (policyConfig[i].mandatory && (pol === undefined || !pol.value)) {
      errors.push(policyConfig[i].id);
    }
  }
  return state.set('policiesError', fromJS(errors));
};

function contractingPageReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_CONTRACT_ACTION:
      return initialState.merge({
        productConfig: action.productConfig,
      });
    case NEXT_STEP_ACTION:
      return state.set('step', state.get('step') + 1);
    case LAST_STEP_ACTION:
      return state.set('step', state.get('step') - 1);
    case CONFIRMATE_CONTRACT_ACTION:
    case VALIDATE_CONTRACT_ACTION:
      return state.set('isConfirmationLoading', true);
    case CANCEL_CONFIRMATION_ACTION:
    case CANCEL_LOAD_ACTION:
      return state.set('isConfirmationLoading', false);
    case UPDATE_FEE_ACTION:
      return state.set('fee', action.newFee);
    case SET_POLICY_ACTION:
      return setPolicy(state, action);
    case SET_POLICY_ERROR_ACTION:
      return setPolicyError(state);
    case SET_CODE_ACTION:
      return state.set('code', action.code);
    case SET_LOADED_DRAFT_ACTION:
      return setLoadedDraft(state, action);
    default:
      return state;
  }
}

export default contractingPageReducer;
