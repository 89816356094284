import { call, take, put, takeLatest, cancel } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getNet, getWPPosts } from 'utils/networking';
import { LOAD_NEWS, LOAD_WPPOSTS } from './constants';
import { setNews, setWPPosts } from './actions';

export function* loadNewsSaga() {
  const qparams = { limit: 2 };
  const response = yield call(getNet, { api: 'api/news', qparams });
  yield put(setNews(response.items));
}

export function* loadWPPostsSaga() {
  const response = yield call(getWPPosts);
  yield put(setWPPosts(response));
}

export function* defaultSaga() {
  const watchers = [
    yield takeLatest(LOAD_NEWS, loadNewsSaga),
    yield takeLatest(LOAD_WPPOSTS, loadWPPostsSaga),
  ];

  yield take(LOCATION_CHANGE);

  for (let i = 0; i < watchers.length; i += 1) {
    yield cancel(watchers[i]);
  }
}

// All sagas to be loaded
export default [defaultSaga];
