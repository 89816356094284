import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import logo from 'assets/images/logodivinaseguros.svg';
import logoAsegrup from 'assets/images/logodivinasegurosasegrup.svg';

export class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMutua: true,
      isHomepage: props.location.pathname === '/',
    };
  }

  componentWillReceiveProps(nextProps) {
    const isAsegrup =
      this.props.enterprise &&
      this.props.enterprise.toString().toLowerCase() === 'asegrup';
    const isCorreduria =
      this.props.enterprise &&
      this.props.enterprise.toString().toLowerCase() === 'corredurias';
    const isMutua = !isCorreduria && !isAsegrup;

    const isNextAsegrup =
      nextProps.enterprise &&
      nextProps.enterprise.toString().toLowerCase() === 'asegrup';
    const isNextCorreduria =
      nextProps.enterprise &&
      nextProps.enterprise.toString().toLowerCase() === 'corredurias';
    const isNextMutua = !isNextCorreduria && !isNextAsegrup;

    this.setState({ isHomepage: nextProps.location.pathname === '/' });

    if (isMutua !== isNextMutua) {
      this.setState({ isMutua: isNextMutua });
    }
  }
  render() {
    return (
      <div className="main-header-outer section">
        <div className="outer-container">
          {this.state.isHomepage ||
          this.state.isMutua ||
          this.state.isCorreduria ? (
            <header className="main-header">
              {this.state.isHomepage ? (
                <div className="main-header__logo-wrapper">
                  <h1>
                    <img
                      className="main-header__logo"
                      src={logo}
                      alt="Aseguradora Divina Seguros"
                    />
                  </h1>
                </div>
              ) : (
                <Link className="main-header__logo-wrapper" to="/">
                  <img
                    className="main-header__logo"
                    src={logo}
                    alt="Divina Seguros"
                  />
                </Link>
              )}
              <div className="main-header__option u-only-up-tablet">
                <a
                  href="https://www.divinaseguros.com/corporativo"
                  className="link link--small"
                >
                  ¿Quiénes somos?
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet">
                <a
                  href="https://blog.divinaseguros.com/"
                  className="link link--small"
                >
                  Blog
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet">
                <a
                  href="/"
                  className="link link--small"
                  onClick={(evt) => {
                    evt.preventDefault();
                    browserHistory.push('/cuadromedico');
                  }}
                >
                  Cuadro médico
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet">
                <a
                  href="/"
                  className="link link--small"
                  onClick={(evt) => {
                    evt.preventDefault();
                    browserHistory.push('/talleres');
                  }}
                >
                  Talleres
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet">
                <a
                  href="https://www.divinaseguros.com/corporativo/recursos-humanos/"
                  className="link link--small"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Trabaja con nosotros
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet">
                <a
                  href="https://www.divinaseguros.com/corporativo/contacto"
                  className="link link--small"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Contacto
                </a>
              </div>

              <div className="main-header__option u-only-up-tablet is-last">
                <a
                  href="https://www.divinaseguros.com/clientes/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link link--small link--icon"
                >
                  Área clientes{' '}
                  <span className="link__icon icon icon--padlock" />
                </a>
              </div>
              <button
                className={`main-header__menu u-only-mobile icon ${
                  this.props.isActive ? 'icon--plus' : 'icon--main-menu'
                }`}
                onClick={this.props.onActiveMenu}
                style={
                  this.props.isActive
                    ? { transform: 'rotate(45deg)' }
                    : { transform: 'rotate(0deg)' }
                }
              />
            </header>
          ) : (
            <header className="main-header">
              <a className="main-header__logo-wrapper" href="/seguro/asegrup">
                <img
                  className="main-header__logo"
                  src={logoAsegrup}
                  alt="Divina Seguros Asegrup"
                />
              </a>
            </header>
          )}
        </div>
      </div>
    );
  }
}

MainHeader.propTypes = {
  onActiveMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default connect(null)(MainHeader);
