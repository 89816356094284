import { fromJS } from 'immutable';

export const initialStateStage = fromJS({
  data: {
    lastName: 'Beta Tester',
    genre: '1',
    height: '165',
    nif: '12345678Z',
    name: 'Testencio',
    mobilePhone: '628199311',
    birthDate: '01/01/1950',
    phone: '961234567',
    province: 'Valencia',
    town: 'Valencia',
    country: '724',
    postalCode: '46002',
    relationship: 'Es el tomador',
    weight: '80',
    profession: '3412',
    nationality: '724',
    email: 'correo@mail.com',
    direction: 'Calle inventada 123',
  },
  isPolicyHolderAnInsured: false,
  isEditing: false,
  paymentInfo: {
    paymentPeriod: '3',
    account: '3021004567740100213986',
    startDatePostponedMonths: 1,
  },
  isEditingPaymentInfo: true,
  beneficiariesInfo: {},
  isEditingBeneficiaries: true,
  allowNie: false,
});
