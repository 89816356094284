import { fromJS } from 'immutable';
import {
  SET_FILTER_DATA_ACTION,
  SET_QUERY_PARAMS_ACTION,
  SET_SORT_ACTION,
  SEARCH_ACTION,
  SET_SEARCH_SPECIALIST_ACTION,
  SET_SEARCH_LOCATION_ACTION,
  SET_SEARCH_RESULT_ACTION,
  SET_IS_LOADING_SEARCH_ACTION,
  SET_IS_LOADING_MORE_ACTION,
  LOAD_MORE_ACTION,
  SET_MORE_RESULTS_ACTION,
  SET_FORM_ACTION,
  SET_COORDS_ACTION,
} from './constants';

const initialState = fromJS({
  form: {},
  sortType: 'priority',
  isLoadingSearch: false,
  isLoadingMore: false,
});

function medicalTeamPageReducer(state = initialState, action) {
  switch (action.type) {

    case SET_QUERY_PARAMS_ACTION:
      return state.set('qparams', action.values);

    case SET_FILTER_DATA_ACTION:
      return state.set('filterDataMap', action.filterDataMap);

    case SET_SORT_ACTION:
      return state.merge({
        searchResult: undefined,
        sortType: action.value,
      });

    case SEARCH_ACTION:
      return state.merge({
        searchResult: undefined,
        numTotalResults: undefined,
        isLoadingSearch: true,
      });

    case SET_SEARCH_SPECIALIST_ACTION:
      return state.set('searchSpecialist', action.value);

    case SET_SEARCH_LOCATION_ACTION:
      return state.set('searchLocation', action.value);

    case LOAD_MORE_ACTION:
      return state.set('isLoadingMore', true);

    case SET_SEARCH_RESULT_ACTION:
      return state.merge({
        searchResult: action.response.items,
        numTotalResults: action.response.numberOfResults,
        isLoadingSearch: false,
      });

    case SET_IS_LOADING_SEARCH_ACTION:
      return state.set('isLoadingSearch', action.value);

    case SET_IS_LOADING_MORE_ACTION:
      return state.set('isLoadingMore', action.value);

    case SET_MORE_RESULTS_ACTION:
      return state.merge({
        numTotalResults: action.response.numberOfResults,
        searchResult: state.get('searchResult').toJS().concat(action.response.items),
        isLoadingMore: false,
      });

    case SET_FORM_ACTION:
      return state.set('form', action.value);

    case SET_COORDS_ACTION:
      return state.set('coords', { latitude: action.value.latitude, longitude: action.value.longitude });

    default:
      return state;
  }
}

export default medicalTeamPageReducer;
