/*
 *
 * NewsViewer reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SET_NEWS,
  SET_WPPOSTS,
} from './constants';

const initialState = fromJS({
});

function newsViewerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEWS:      
      return state.set('news', action.news);
    case SET_WPPOSTS:
      return state.set('wpposts', action.wpposts);
    default:
      return state;
  }
}

export default newsViewerReducer;
