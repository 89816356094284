/*
 *
 * NewsViewer constants
 *
 */

export const LOAD_NEWS = 'app/NewsViewer/LOAD_NEWS';
export const SET_NEWS = 'app/NewsViewer/SET_NEWS';
export const LOAD_WPPOSTS = 'app/NewsViewer/LOAD_WPPOSTS';
export const SET_WPPOSTS = 'app/NewsViewer/SET_WPPOSTS';
