import { createSelector } from 'reselect';

const selectMenuDomain = () => (state) => state.get('menuState').toJS();

const makeSelectSelectedOption = () => createSelector(
  selectMenuDomain(),
  (substate) => substate.selectedOption
);

const makeSelectIsActive = () => createSelector(
  selectMenuDomain(),
  (substate) => substate.isActive
);

export {
  makeSelectSelectedOption,
  makeSelectIsActive,
};
