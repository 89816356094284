import { cancel, put, push, call, take, takeLatest } from 'redux-saga/effects';
import { getNet } from 'utils/networking';
import { LOCATION_CHANGE } from 'react-router-redux';
import { setFaqsAction } from './actions';
import { LOAD_FAQS_ACTION } from './constants';
import map from 'lodash/map';

import configFaqs from '../../config/faqs.config.json';

function getMultipleRandom(arr, num) {
  const random = [...arr].sort(() => 0.5 - Math.random());
  return random.slice(0, num);
}

function* loadFaqs({ product }) {
  const response = yield getMultipleRandom(map(configFaqs.faqs).filter( faq => faq.product.toString() === product), 5);
//  console.log(JSON.stringify(response));
  yield put(setFaqsAction(JSON.stringify(response)));
}

function* defaultSaga() {
  const watchers = [yield takeLatest(LOAD_FAQS_ACTION, loadFaqs)];

  yield take(LOCATION_CHANGE);

  for (let i = 0; i < watchers.length; i += 1) {
    yield cancel(watchers[i]);
  }
}

export default [
  defaultSaga,
];

