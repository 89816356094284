import find from 'lodash/find';
import { fromJS } from 'immutable';
import { UPLOAD_FILE_ACTION, DELETE_FILE_ACTION } from './constants';
import {
  SET_FILES_FROM_DRAFT_ACTION,
  SET_FILES_SENDED_ACTION,
} from '../constants';

const initialState = fromJS({
  file: undefined,
});

const setUploadedFile = (state, action) => {
  if (find(action.files, { nif: 'null' }) !== undefined) {
    return state.merge({
      file: fromJS({ name: 'EXTRACTO GUARDADO', fromDraft: true }),
    });
  }
  return state;
};

const setFilesSended = (state, action) => {
  const file = find(action.files, { nif: null });
  if (file) {
    return state.set('documentId', file.documentId);
  }
  return state;
};

function bankStatementUploaderReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_ACTION:
      return state.set('file', action.file);
    case SET_FILES_FROM_DRAFT_ACTION:
      return setUploadedFile(state, action);
    case DELETE_FILE_ACTION:
      return state.set('file', undefined);
    case SET_FILES_SENDED_ACTION:
      return setFilesSended(state, action);
    default:
      return state;
  }
}

export default bankStatementUploaderReducer;
