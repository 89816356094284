import moment from 'moment';
import { isOfLegalAge } from 'utils/dateFactory';
import contactInfo from '../../../config/contact.config.json';

export const SPANISH_NATIONALITY_CODE = '724';
export const NO_OPTION_SELECTED = -1;

export const valrequired = value =>
  value && value != NO_OPTION_SELECTED
    ? undefined
    : 'Este campo es obligatorio';

export const valNotInside = (value, array) => {
  const timesFinded = array.filter(
    item => item.toUpperCase() === value.toUpperCase(),
  ).length;
  if (timesFinded > 0) {
    return 'Este valor no puede coincidir con el de otra persona del contrato';
  }
  return undefined;
};

export const valnif = function validateNIF(value, allowNie) {
  var nif = value.toUpperCase();
  const nifRegex = /^[XYZ]?\d{7,8}[A-Z]$/;
  if (nifRegex.test(nif)) {
    const nieNif = nieToNif(nif);
    if(nif != nieNif)
    {
      if(!allowNie)
      {
        return (
          'Para continuar con su solicitud de seguro llame al ' +
          contactInfo.sellPhone
        );
      }
      nif = nieNif;
    }
    const nifNumber = nif.substr(0, nif.length - 1);   
    const nifLetter = nif.substr(nif.length - 1, 1);
    const appropriateLetterPosition = nifNumber % 23;
    let letterChoice = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letterChoice = letterChoice.substr(appropriateLetterPosition, 1);
    if (letterChoice !== nifLetter || value.length !== 9) {
      return 'El NIF no es válido';
    }
    return undefined;
  }
  return 'El NIF no es válido';
};

function nieToNif(value)
{
  const nieNumber = value.substr(1, value.length);
  const startsWith = value.substr(0, 1);
  switch(startsWith)
  {
    case "X":
      return "0" + nieNumber;
    case "Y":
      return "1" + nieNumber;
    case "Z":
      return "2" + nieNumber;
  }
  return value;
}

export const valDate = value => {
  if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
    return 'La fecha no es válida';
  }
  return undefined;
};

export const valNotFutureDate = value => {
  if (moment(value, 'DD/MM/YYYY', true).isAfter(Date.now())) {
    return 'La fecha no es válida';
  }
  return undefined;
};

export const valAccountNumber = value => {
  const parsedAccountNumber = value.toString().replace(/\s/g, '');
  if (!isNaN(parsedAccountNumber) && parsedAccountNumber.length === 22) {
    return undefined;
  }
  return 'El número de cuenta debe ser válido';
};

export const valtlfMovil = value => {
  const mobileRegex = /^[6-7][0-9]{8}$/;

  if (mobileRegex.test(value) === true) {
    return undefined;
  }
  return 'El teléfono móvil no es válido';
};

export const valPhone = value => {
  const mobileRegex = /^(\+34|0034|34)?[6|7|8|9][0-9]{8}$/;

  if (value && !mobileRegex.test(value)) {
    return 'El teléfono no es válido';
  }
  return undefined;
};

export const valEmail = value => {
  const emailRegex = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;

  if (emailRegex.test(value) === true) {
    return undefined;
  }
  return 'La dirección de correo electrónico no es válida';
};

export const valNationality = (value, allowNie) => {
  if (allowNie || value === SPANISH_NATIONALITY_CODE) {
    return undefined;
  }
  return (
    'Para continuar con su solicitud de seguro llame al ' +
    contactInfo.sellPhone
  );
};

export const valLegalAge = value => {
  if (isOfLegalAge(value)) {
    return undefined;
  }
  return 'El tomador no puede ser menor de edad';
};

export const valIntegerNumber = value => {
  if (parseFloat(value) === parseInt(value) && !isNaN(value)) {
    return undefined;
  }
  return 'No es un número entero válido';
};
