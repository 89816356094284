import { getBirthDateFromAge, getAge, getFormatedBirthDate } from 'utils/dateFactory';

const MAX_AGE_FAMILY = 54;
const NUM_PEOPLE_COUPLE = 2;
const isRangeFamiliar = age => (age <= MAX_AGE_FAMILY);

const insuredRelationshipIsValue = (insureds, value) => {
  for (let i = 0; i < insureds.length; i += 1) {
    if (insureds[i].data.relationship === value) {
      return true;
    }
  }
  return false;
};


const areFamilyForm = dataForm => {
  for (let p = 0; p < parseInt(dataForm.personNumberSelected); p += 1) {
     if (getBirthDateFromAge(dataForm[`age${p}`])>MAX_AGE_FAMILY){
       return false;
     }
    }
  return true;
}

const areFamily = insureds => {
  if (insureds.length <= NUM_PEOPLE_COUPLE) {
    return false;
  }

  for (let i = 0; i < insureds.length; i += 1) {
    if (!isRangeFamiliar(getAge(insureds[i].data.birthDate))) {
      return false;
    }
  }
  return true;
};

const textRelationshipInsureds = insureds => {
  const hasInsuredTaker = insuredRelationshipIsValue(insureds, 'Es el tomador');
  const hasSpouse = insuredRelationshipIsValue(insureds, '3');

  if (hasInsuredTaker && hasSpouse && insureds.length === NUM_PEOPLE_COUPLE) {
    return 'Pareja';
  }

  if (areFamily(insureds)) {
    return 'Familia';
  }

  return null;
};



const textRelationshipForm = dataForm => {
 
  if ( dataForm.relationship &&  parseInt(dataForm.personNumberSelected) === NUM_PEOPLE_COUPLE) {
    return 'Pareja';
  }

  if (areFamilyForm(dataForm)) {
    return 'Familia';
  }

  return null;
};

const calculatorHasAgesSelector = (productConfig) => {
  if (productConfig.calculator) {
    for (let i = 0; i < productConfig.calculator.form.length; i += 1) {
      if (productConfig.calculator.form[i].type === 'ages-selector') {
        return true;
      }
    }
  }
  return false;
};

const getConstants = (productConfig) => {
  const constants = {};
  if (productConfig.constants) {
    for (let k = 0; k < productConfig.constants.length; k += 1) {
      // Add constants
      constants[productConfig.constants[k].formName] = productConfig.constants[k].value;
    }
  }
  return constants;
};

const getRequestModel = (productConfig, form, modalitiesArray, ageCont) => {
  const result = [];
  for (let j = 0; j < modalitiesArray.length; j += 1) {
    const mod = modalitiesArray[j];
    const data = {
      modality: mod.modality,
      subtype: mod.modality,
    };
    const params = {};
    if (productConfig.calculator) {
      for (let k = 0; k < productConfig.calculator.form.length; k += 1) {
        // Special case age selector
        if (productConfig.calculator.form[k].type !== 'ages-selector') {
          params[productConfig.calculator.form[k].formName] =
            form[productConfig.calculator.form[k].formName];
        } else {
          params.birthDate = getBirthDateFromAge(form[`age${ageCont}`]);
        }
      }
    }
    result.push({
      type: productConfig.id,
      ...data,
      params: {
        ...params,
        ...getConstants(productConfig),
      },
    });
  }
  return result;
};

const calculatorIsHealth = productConfig => {
  if (productConfig.calculator) {
    return (productConfig.id === '30');
  }
  return false;
};

// Solo para Salud
const getRequestModelByModalities = (productConfig, form, modalitiesArray, insuredsStep) => {
  const result = [];
  for (let j = 0; j < modalitiesArray.length; j += 1) {
    const mod = modalitiesArray[j];
    const data = {
      modality: mod.modality,
      subtype: mod.modality,
    };
    const insureds = [];
    let txtRelation;

    if (productConfig.calculator) {
      if (insuredsStep[0].data.birthDate) {
        for (let i = 0; i < insuredsStep.length; i += 1) {
          const insured = {};
          insured.birthDate = getFormatedBirthDate(insuredsStep[i].data.birthDate);
          insured.id = (i + 1);
          insured.postalCode = insuredsStep[i].data.postalCode;
          insureds.push(insured);
        }
        txtRelation=textRelationshipInsureds(insuredsStep)
      } else {
        for (let p = 0; p < parseInt(form.personNumberSelected); p += 1) {
          const insured = {};
          for (let k = 0; k < productConfig.calculator.form.length; k += 1) {
            // Special case age selector
            if (productConfig.calculator.form[k].type !== 'ages-selector') {
              insured[productConfig.calculator.form[k].formName] =
                form[productConfig.calculator.form[k].formName];
            } else {
              insured.birthDate = getBirthDateFromAge(form[`age${p}`]);
            }
          }
          insured.id = (p + 1);
          insureds.push(insured);
        }
        txtRelation=textRelationshipForm(form);
      }
    }
    result.push({
      type: productConfig.id,
      ...data,
      params: {
        RelationshipInsureds: txtRelation,
        insureds,
        ...getConstants(productConfig),
      },
    });
  }
  return result;
};


export const getCalculatorRequest = (productConfig, form, insureds) => {
  if (productConfig.modalitiesInfo) {
    const modalitiesGroup = productConfig.modalitiesInfo.modalitiesGroup;
    let modalitiesArray = [];
    for (let i = 0; i < modalitiesGroup.length; i += 1) {
      modalitiesArray = modalitiesArray.concat(modalitiesGroup[i].modalities);
    }

    if ((calculatorIsHealth(productConfig))) {
      return getRequestModelByModalities(productConfig, form, modalitiesArray, insureds);
    } else if (calculatorHasAgesSelector(productConfig)) {
      let result = [];
      for (let i = 0; i < form.personNumberSelected; i += 1) {
        result = result.concat(getRequestModel(productConfig, form, modalitiesArray, i));
      }
      return result;
    }
    return getRequestModel(productConfig, form, modalitiesArray);
  }
  if (productConfig.calculator.type === 'selector') {
    const result = [];
    for (let i = 0; i < productConfig.calculator.form.options.length; i += 1) {
      const res = {
        type: productConfig.id,
        params: {
          ...getConstants(productConfig),
        },
      };
      res.params[productConfig.calculator.form.formName] = productConfig.calculator.form.options[i];
      result.push(res);
    }
    return result;
  }
  return null;
};

export default getCalculatorRequest;
