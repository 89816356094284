import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

function ProductLink(props) {
  const onLinkClick = e => {
    if (e) {
      e.preventDefault();
    }
    browserHistory.push(`/seguro/${props.data.path}`);
  };
  return (
    <li>
      <a href="" onClick={onLinkClick}>
        {props.data.title}
      </a>
    </li>
  );
}

ProductLink.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductLink;
