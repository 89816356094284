import { createSelector } from 'reselect';

const selectCallRequestBoxDomain = () => (state) =>
  state.get('callRequestBox').toJS();

const makeSelectModalActive = () =>
  createSelector(
    selectCallRequestBoxDomain(),
    (substate) => substate.showModal,
  );

const makeSelectUrl = () =>
  createSelector(selectCallRequestBoxDomain(), (substate) => substate.url);

const makeSelectRequestSended = () =>
  createSelector(
    selectCallRequestBoxDomain(),
    (substate) => substate.formSended,
  );

export { makeSelectModalActive, makeSelectRequestSended, makeSelectUrl };
