import makeActionCreator from 'utils/makeActionCreator';
import {
    LOAD_OFFICES,
    SET_RESPONSE,
    FILTER_OFFICES,
    SET_OFFICE_DISTANCE,
    SET_COMBO_OPTIONS,
    SELECT_OFFICE,
    TOGGLE_MAP,
    SET_USER_COORDS,
} from './constants';

export const loadOfficesAction = makeActionCreator(LOAD_OFFICES);
export const setResponseAction = makeActionCreator(SET_RESPONSE, 'response');
export const setOfficesByLocationAction = makeActionCreator(FILTER_OFFICES, 'offices');
export const setOfficeDistanceAction = makeActionCreator(SET_OFFICE_DISTANCE, 'offices');
export const setComboOptionsAction = makeActionCreator(SET_COMBO_OPTIONS, 'options');
export const setOfficeSelected = makeActionCreator(SELECT_OFFICE, 'officeSelected');
export const toggleMapMobile = makeActionCreator(TOGGLE_MAP);
export const setUserCoordinates = makeActionCreator(SET_USER_COORDS, 'coords');
