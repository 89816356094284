import { createSelector } from 'reselect';


const selectMedicalTeamPageDomain = () => (state) => state.get('medicalTeamPage') ? state.get('medicalTeamPage').toJS() : {};

const makeSelectFilterData = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate && substate.filterDataMap
);

const makeSelectQueryParams = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate && substate.qparams
);

const makeSelectIsLoadingSearch = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.isLoadingSearch
);

const makeSelectIsLoadingMore = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.isLoadingMore
);

const makeSelectSearchResult = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.searchResult
);

const makeSelectNumTotalResults = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.numTotalResults
);

const makeSelectSearchSpecialist = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.searchSpecialist
);

const makeSelectSearchLocation = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.searchLocation
);

const makeSelectSortType = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.sortType
);

const makeSelectForm = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.form
);

const makeSelectCoords = () => createSelector(
  selectMedicalTeamPageDomain(),
  (substate) => substate.coords
);

export {
  makeSelectFilterData,
  makeSelectQueryParams,
  makeSelectIsLoadingSearch,
  makeSelectIsLoadingMore,
  makeSelectSearchResult,
  makeSelectNumTotalResults,
  makeSelectSearchSpecialist,
  makeSelectSearchLocation,
  makeSelectSortType,
  makeSelectForm,
  makeSelectCoords,
};
