import { getProductConfigById, getObjectPropertyByPath } from 'utils/functions';
import { getDateFromString, getAge } from 'utils/dateFactory';
import find from 'lodash/find';

const setModality = (action, productConfig, state) => {
  let form = state.get('form');
  const draftModality = getObjectPropertyByPath(
    action.contract,
    'petitions[0].mode',
  );
  if (productConfig && draftModality) {
    productConfig.modalitiesInfo.modalitiesGroup.forEach((mod, i) => {
      const selectedModality = find(mod.modalities, {
        modalityName: draftModality,
      });
      if (selectedModality) {
        form = form.merge({
          selectedModality: draftModality,
          selectedModalityGrp: i,
        });
      }
    });
  }
  return state.set('form', form);
};

const setInsureds = (insureds, state) => {
  let form = state.get('form');
  if (insureds) {
    insureds.forEach((insured, i) => {
      if (insured.data) {
        form = form.set(
          `age${i}`,
          getAge(getDateFromString(insured.data.birthDate)),
        );
      }
    });
  }
  form = form.set('personNumberSelected', insureds.length);
  return state.set('form', form);
};

const setPostalCode = (insureds, state) => {
  let form = state.get('form');
  if (insureds && insureds[0].address) {
    form = form.set('postalCode', insureds[0].address.cp);
  }
  return state.set('form', form);
};

const setProductAddons = (productConfig, coverageRequestData, state) => {
  let form = state.get('form');
  const addons = getObjectPropertyByPath(
    productConfig,
    'modalitiesInfo.addons',
  );
  if (addons && coverageRequestData) {
    addons.forEach(addon => {
      if (coverageRequestData[addon.id] === 'Si') {
        form = form.set(addon.id, addon.price);
      }
    });
  }
  return state.set('form', form);
};

const setCoverages = (insureds, state) => {
  let form = state.get('form');

  if (!insureds[0].coverages) {
    return state;
  }

  const coverages = insureds[0].coverages;

  if (coverages.ImporteAsegurado) {
    form = form.set('capital', coverages.ImporteAsegurado);
  }

  if (coverages.SubsidioMensual) {
    form = form.set('fee', coverages.SubsidioMensual / 100);
  }

  return state.set('form', form);
};

export default function setLoadedDraft(state, action) {
  const draftProduct = action.contract.petitions[0].product;
  const productConfig = getProductConfigById(draftProduct);

  let newState = setModality(action, productConfig, state);
  newState = setInsureds(
    getObjectPropertyByPath(action.contract, 'petitions[0].insureds'),
    newState,
  );
  newState = setPostalCode(
    getObjectPropertyByPath(action.contract, 'petitions[0].insureds'),
    newState,
  );
  newState = setProductAddons(
    productConfig,
    getObjectPropertyByPath(
      action.contract,
      'petitions[0].coverageRequestData',
    ),
    newState,
  );
  newState = setCoverages(
    getObjectPropertyByPath(action.contract, 'petitions[0].insureds'),
    newState,
  );

  return newState.set('productConfig', productConfig);
}
