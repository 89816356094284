import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import localization from 'moment/locale/es';
import RecoveryContractModal from 'containers/RecoveryContractModal';
import ModalCallRequest from '../../components/ModalCallRequest';
import {
  makeSelectModalActive,
  makeSelectUrl,
  makeSelectRequestSended,
} from 'containers/CallRequestBox/selectors';
import {
  showModalAction,
  markAsSendedAction,
} from 'containers/CallRequestBox/actions';
import MainHeader from '../../components/MainHeader';
import MainHeaderContracting from '../../components/MainHeaderContracting';
import Menu from '../Menu';
import MainFooter2 from '../../components/MainFooter2';
import CookieConsent from '../../components/CookieConsent';

import { activeMenuAction } from '../Menu/actions';
import { makeSelectIsActive } from '../Menu/selectors';
import config from 'config.json';

if (process.env.REACT_APP_ENVIRONMENT !== 'test') {
  moment.updateLocale('es', localization);
}

export class App extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const isContracting =
      this.props.location.pathname.indexOf('contratar') !== -1;
    const getProductFromLocation = () => {
      if (window.location.pathname.indexOf('/seguro/') == 0) {
        let productSplitted = window.location.pathname.split('/');
        return productSplitted[2];
      }
    };
    const enterprise = getProductFromLocation()
      ? config.products[getProductFromLocation()].enterprise
      : '';
    const props = {
      isActive: this.props.isActive,
      onActiveMenu: this.props.onActiveMenu,
      enterprise: enterprise,
    };

    return (
      <div>
        <div>
          {isContracting ? (
            <MainHeaderContracting
              showModal={() => this.props.onShowModal(true)}
            />
          ) : (
            <MainHeader {...props} location={this.props.location} />
          )}
          {isContracting ? null : <Menu {...props} />}
          {React.Children.toArray(this.props.children)}
          {/* <MainFooter important={isContracting} {...props} /> */}

          <ModalCallRequest
            idForm="Home"
            privacyPolicyUrl={this.props.privacyPolicyUrl}
            isActive={this.props.showModal}
            onClose={() => this.props.onShowModal(false)}
            isRequestSended={this.props.isRequestSended}
            onShow={(event) => this.props.onShowModal(event, true)}
            markSended={this.props.markAsSended}
          />
          <RecoveryContractModal location={this.props.location} />
          <CookieConsent />
        </div>
        <MainFooter2 important={isContracting} {...props} />
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
  onActiveMenu: PropTypes.func.isRequired,
  onShowModal: PropTypes.func,
  markAsSended: PropTypes.func,
  isActive: PropTypes.bool,
  showModal: PropTypes.bool,
  isRequestSended: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isActive: makeSelectIsActive(),
  showModal: makeSelectModalActive(),
  privacyPolicyUrl: makeSelectUrl(),
  isRequestSended: makeSelectRequestSended(),
});

function mapDispatchToProps(dispatch) {
  return {
    onActiveMenu: (position) => dispatch(activeMenuAction(position)),
    onShowModal: (showModal) => {
      dispatch(showModalAction(showModal));
    },
    markAsSended: () => dispatch(markAsSendedAction(true)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
