import { createSelector } from 'reselect';

const selectContractingPageDomain = () => state =>
  state.get('contractingPage') ? state.get('contractingPage').toJS() : {};

const makeSelectFee = () =>
  createSelector(selectContractingPageDomain(), substate => substate.fee);

const makeSelectStep = () =>
  createSelector(selectContractingPageDomain(), substate => substate.step);

const makeSelectProductConfig = () =>
  createSelector(
    selectContractingPageDomain(),
    substate => substate.productConfig,
  );

const makeSelectIsConfirmationLoading = () =>
  createSelector(
    selectContractingPageDomain(),
    substate => substate.isConfirmationLoading,
  );

const makeSelectPolicies = () =>
  createSelector(selectContractingPageDomain(), substate => substate.policies);

const makeSelectPoliciesError = () =>
  createSelector(
    selectContractingPageDomain(),
    substate => substate.policiesError,
  );

const makeSelectDraftCode = () =>
  createSelector(selectContractingPageDomain(), substate => substate.code);

const makeSelectDraftKey = () =>
  createSelector(selectContractingPageDomain(), substate => substate.key);

const makeSelectPromoterNif = () =>
  createSelector(
    selectContractingPageDomain(),
    substate => substate.promoterNif,
  );

const makeSelectAllowNie = () =>
createSelector(
  selectContractingPageDomain(),
  substate => substate.allowNie,
);

export {
  makeSelectFee,
  makeSelectStep,
  makeSelectProductConfig,
  makeSelectIsConfirmationLoading,
  makeSelectPoliciesError,
  makeSelectPolicies,
  makeSelectDraftCode,
  makeSelectDraftKey,
  makeSelectPromoterNif,
  makeSelectAllowNie,
};
