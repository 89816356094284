import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { showModalAction } from './actions';
import { makeSelectModalActive, makeSelectRequestSended } from './selectors';
import contactInfo from '../../config/contact.config.json';

class CallRequestBox extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  showModal = () => {
    this.props.onShowModal(true, this.props.url);
  };
  render() {
    const phone = this.props.phone ? this.props.phone : contactInfo.sellPhone;
    return (

      this.props.displayCustomLink ? (
        <a
          href="/"
          className={this.props.className}
          onClick={(event) => {
            event.preventDefault();
            this.showModal();
          }}
        >
          {this.props.customText ? 
            this.props.customText : "Te llamamos"
        }
          
        </a>
      ) : (
      <div
        style={{ maxWidth: '500px', margin: 'auto', textAlign: 'center' }}
        className={this.props.className}
      >
        <div
          className={`${
            this.props.displayCallUs ||
            this.props.displayInline ||
            this.props.displayButton ||
            this.props.displayLink ||
            this.props.displayLinkNoIcon 
              ? 'u-only-up-tablet'
              : 'box box--insider u-margin-bottom-medium u-only-up-tablet'
          } `}
        >
          {this.props.displayCallUs ? (
            <div
              className="u-flex u-margin-top"
              style={{ alignItems: 'center' }}
            >
              <div className="u-flex-grow1">
                <p className="u-font-bold" style={{ fontSize: '20px' }}>
                  o llámanos al{' '}
                  <span
                    className="is-numeric"
                    style={{ display: 'inline-block' }}
                  >
                    {phone}
                  </span>
                </p>
              </div>
            </div>
          ) : this.props.displayInline ? (
            <div className=" u-margin-top" style={{ alignItems: 'center' }}>
              <div>
                <p className="u-font-bold" style={{ fontSize: '20px' }}>
                  ¡Llámanos!{' '}
                  <span
                    className="is-numeric"
                    style={{ display: 'inline-block' }}
                  >
                    {' '}
                    {phone}
                  </span>
                </p>
              </div>
              <div className="u-margin-top">
                <a
                  className="button button--primary button--big button--highlight"
                  onClick={this.showModal}
                >
                  o te llamamos
                </a>
              </div>
            </div>
          ) : this.props.displayLink ? (
            <a
              href="/"
              className="link link--icon u-margin-left-medium"
              onClick={(event) => {
                event.preventDefault();
                this.showModal();
              }}
            >
              ¿Te llamamos?
              <span className="link__icon icon icon--phone-in" />
            </a>
          ) :  this.props.displayLinkNoIcon ? (
            <a
              href="/"
              className="link"
              onClick={(event) => {
                event.preventDefault();
                this.showModal();
              }}
            >
              Te llamamos
            </a>
          ) : this.props.displayButton ? (
            <a
              href="/"
              className="button button--primary"
              onClick={(event) => {
                event.preventDefault();
                this.showModal();
              }}
            >
              Te llamamos             
            </a>
          ) : (
            <div className="u-text-center">
              <p className="u-margin-bottom-small">
                <span className="u-font-bold">¿Te interesa?</span> ¡Llámanos!
              </p>
              <p className="u-font-h1 u-font-bold u-margin-bottom is-numeric">
              
              <a href={`tel:+34${phone.split(' ').join('')}`}
                className="is-numeric u-text-decoration-none">
                {phone}
              </a>
              
              </p>
              <a
                className="button button--primary button--big"
                onClick={this.showModal}
              >
                o te llamamos
              </a>
            </div>
          )}
        </div>
        <div className="landing-phone-block">
          <div className="u-flex">
            <div className="u-flex-grow1 landing-col landing-col--phone">
              <a href={`tel:+34${phone.split(' ').join('')}`}
                className="u-font-h4 u-font-bold is-numeric u-text-decoration-none u-light-color">
                {phone}
              </a>
            </div>
            <div className="u-flex-grow1 landing-col landing-col--call">
              <a className="landing-col__link" onClick={this.showModal}>
                Te llamamos
              </a>
            </div>
          </div>
        </div>
      </div>
    )
    );
  }
}

CallRequestBox.propTypes = {
  onShowModal: PropTypes.func,
  phone: PropTypes.any,
  displayCallUs: PropTypes.bool,
  displayInline: PropTypes.bool,
  displayLink: PropTypes.bool,
  displayLinkNoIcon: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  showModal: makeSelectModalActive(),
  isRequestSended: makeSelectRequestSended(),
});

function mapDispatchToProps(dispatch) {
  return {
    onShowModal: (showModal, url) => {
      dispatch(showModalAction(showModal, url));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallRequestBox);
