import sbjs from 'sourcebuster';

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const setCookie = (cname, cvalue) => {
  const exdays = 30;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const isTrackingEnabled = () => {
  //'cookie_consent_user_accepted'
  if (getCookie('cookie_consent_user_accepted')) {
    const consentLevel = JSON.parse(getCookie('cookie_consent_level'));
    return consentLevel && consentLevel.tracking;
  }
  return false;
};

export const initSbjs = () => {
  if (isTrackingEnabled()) {
    sbjs.init();
  }
};
