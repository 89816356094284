import { fromJS } from 'immutable';
import { SET_LOADED_DRAFT_ACTION } from 'containers/RecoveryContractModal/constants';
import setLoadedDraft from './mapDraft';
import { initialStateStage } from './mocks/stageMock';
import {
  INITIALIZE_POLICYHOLDER_ACTION,
  SET_POLICYHOLDER_ACTION,
  EDIT_POLICYHOLDER_ACTION,
  SET_PAYMENTINFO_ACTION,
  EDIT_PAYMENTINFO_ACTION,
  SET_BENEFICIARIES_ACTION,
  EDIT_BENEFICIARIES_ACTION,
  RESET_STATE_POLICYHOLDER_ACTION,
} from './constants';

const initialStateBase = fromJS({
  data: {
    nationality: '724',
    country: '724',
  },
  isPolicyHolderAnInsured: false,
  isEditing: true,
  paymentInfo: { startDatePostponedMonths: 1 },
  isEditingPaymentInfo: false,
  beneficiariesInfo: {},
  isEditingBeneficiaries: false,
  allowNie: false
});

const initialState = process.env.REACT_APP_ENVIRONMENT === 'development' ? initialStateStage : initialStateBase;

const initializePolicyholder = (state, action) => {
  const isEmpty = Object.keys(action.data).length === 0;
  if (isEmpty) {
    return initialState;
  }

  const paymentInfoFilled = () => {
    const paymentInfo = state.get('paymentInfo').toJS();
    return paymentInfo.account && paymentInfo.paymentPeriod && paymentInfo.startDatePostponedMonths;
  }
  const beneficiariesFilled = Object.keys(state.get('beneficiariesInfo').toJS()).length;

  return state.merge({
    data: action.data,
    isEditing: false,
    isPolicyHolderAnInsured: true,
    isEditingPaymentInfo: !paymentInfoFilled(),
    isEditingBeneficiaries: !beneficiariesFilled && action.beneficiaries,
    hasBeneficiaries: action.beneficiaries,
  });
};

function policyHolderViewReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE_POLICYHOLDER_ACTION:
      return initialState;
    case INITIALIZE_POLICYHOLDER_ACTION: // eslint-disable-line
      return initializePolicyholder(state, action);
    case SET_POLICYHOLDER_ACTION:
      return state.merge({
        data: action.data,
        isEditing: false,
        isEditingPaymentInfo: true,
      });
    case EDIT_POLICYHOLDER_ACTION:
      return state.set('isEditing', true);
    case SET_PAYMENTINFO_ACTION:
      return state.merge({
        paymentInfo: action.data,
        isEditingPaymentInfo: false,
        isEditingBeneficiaries: state.get('hasBeneficiaries'),
      });
    case EDIT_PAYMENTINFO_ACTION:
      return state.set('isEditingPaymentInfo', true);
    case SET_BENEFICIARIES_ACTION:
      return state.merge({
        beneficiariesInfo: action.data,
        isEditingBeneficiaries: false,
      });
    case EDIT_BENEFICIARIES_ACTION:
      return state.set('isEditingBeneficiaries', true);
    case SET_LOADED_DRAFT_ACTION:
      return setLoadedDraft(state, action);
    default:
      return state;
  }
}

export default policyHolderViewReducer;
