import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import config from '../../config.json';
import { browserHistory } from 'react-router';
import { preventPropagation } from 'utils/functions';
import Submenu from './Submenu';
import { selectOptionAction, activeMenuAction } from './actions';
import { makeSelectSelectedOption, makeSelectIsActive } from './selectors';

export class Menu extends React.PureComponent {
  render() {
    const isAsegrup =
      this.props.enterprise && this.props.enterprise === 'Asegrup';
    const isCorreduria =
      this.props.enterprise && this.props.enterprise === 'Corredurias';
    const isMutua = !isAsegrup && !isCorreduria;
    const families = isAsegrup
      ? config.families.asegrup
      : isCorreduria
      ? config.families.corredurias
      : config.families.seguros;
    return !isAsegrup ? (
      <nav className={`main-menu ${this.props.isActive ? 'is-visible' : ''}`}>
        <div className="u-text-center u-margin-top-small u-margin-bottom-tiny u-only-mobile">
          <div className="menu-list">
            <a
              href="https://www.divinaseguros.com/corporativo"
              rel="noopener noreferrer"
              target="_blank"
              className="link"
            >
              ¿Quiénes somos?
            </a>
            <a
              href="https://blog.divinaseguros.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="link"
            >
              Blog
            </a>
            <a
              href="/"
              className="link"
              onClick={(evt) => {
                evt.preventDefault();
                this.props.onCloseMenu();
                browserHistory.push('/cuadromedico');
              }}
            >
              Cuadro médico
            </a>
            <a
              href="/"
              className="link"
              onClick={(evt) => {
                evt.preventDefault();
                this.props.onCloseMenu();
                browserHistory.push('/talleres');
              }}
            >
              Talleres
            </a>
            <a
              href="https://www.divinaseguros.com/corporativo/contacto"
              rel="noopener noreferrer"
              target="_blank"
              className="link"
            >
              Contacto
            </a>
            <a
              href="https://www.divinaseguros.com/clientes"
              rel="noopener noreferrer"
              target="_blank"
              className="link link--icon"
            >
              Área clientes <span className="link__icon icon icon--padlock" />
            </a>
          </div>
        </div>
        <div className="u-flex main-menu__wrapper">
          <div className="main-menu-left u-only-up-desktop">
            <ul className="main-menu__wrapper">
              <li
                className={` ${
                  isAsegrup || isCorreduria ? 'theme-brand' : 'theme-brand'
                } main-menu__option main-menu-marginLeft`}
              >
                &nbsp;
              </li>
            </ul>
          </div>
          <div className="main-menu-center">
            <ul className="main-menu__wrapper">
              {families.map((fam, i) => (
                <li
                  key={i}
                  className={`theme-${fam.theme} main-menu__option ${
                    this.props.selectedOption === i ? 'is-chosed' : ''
                  }`}
                  onClick={() => this.props.onSelectOption(families, i)}
                >
                  {fam.products.length > 1 ? (
                    <a className="main-menu__option-link">
                      <div
                        className={`main-menu__icon icon icon--${fam.theme}`}
                      />
                      <div className="main-menu__name">{fam.title}</div>
                      {fam.products.length > 1 ? (
                        <span className="main-menu__arrow" />
                      ) : null}
                    </a>
                  ) : (
                    <a
                      className="main-menu__option-link"
                      href={`${
                        fam.path == 'tener'
                          ? 'https://www.divinaseguros.com/tener/'
                          : '/seguro/' + fam.path
                      }`}
                    >
                      <div
                        className={`main-menu__icon icon icon--${fam.theme}`}
                      />
                      <div className="main-menu__name">
                        {fam.title}
                        {fam.subtitle ? (
                          <div className="main-menu__subtitle">
                            {fam.subtitle}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>
                  )}
                </li>
              ))}
              <li
                className="theme-brand main-menu__option u-only-mobile"
                onClick={(evt) => {
                  evt.preventDefault();
                  this.props.onCloseMenu();
                  browserHistory.push('/seguro/corredurias');
                }}
              >
                <a
                  className="main-menu__option-link"
                  href="/seguro/corredurias"
                >
                  <div className="main-menu__name">Canal corredurías</div>
                </a>
              </li>
            </ul>
            <Submenu {...families[this.props.selectedOption]} {...this.props} />
          </div>
          <div className="main-menu-right u-only-up-desktop">
            <ul className="main-menu__wrapper">
              <li
                className={`${
                  isMutua ? 'theme-deaths' : 'theme-brand'
                } main-menu__option main-menu-marginRight`}
              >
                &nbsp;
              </li>
            </ul>
          </div>
        </div>
      </nav>
    ) : (
      <div />
    );
  }
}

Menu.propTypes = {
  onSelectOption: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  selectedOption: PropTypes.number,
  isActive: PropTypes.bool,
  enterprise: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  selectedOption: makeSelectSelectedOption(),
  isActive: makeSelectIsActive(),
});

function mapDispatchToProps(dispatch) {
  return {
    onSelectOption: (family, position) => {
      if (position === undefined) {
        dispatch(selectOptionAction(undefined));
      } else if (family[position].products.length > 1) {
        dispatch(selectOptionAction(position));
      } else {
        if (family[position].path == 'tener') {
          window.open(`https://www.divinaseguros.com/tener/`, '_self');
          //browserHistory.push(`/${family[position].path}`);
        } else {
          browserHistory.push(`/seguro/${family[position].path}`);
          dispatch(selectOptionAction(undefined));
          dispatch(activeMenuAction());
        }
      }
    },
    onSelectedProd: (prod, event) => {
      if (event) {
        event.preventDefault();
      }
      preventPropagation(event);
      dispatch(selectOptionAction(undefined));
      dispatch(activeMenuAction());
      browserHistory.push(`/calculadora/${prod}`);
    },
    onContractProd: (prod, event) => {
      if (event) {
        event.preventDefault();
      }
      preventPropagation(event);
      dispatch(selectOptionAction(undefined));
      dispatch(activeMenuAction());
      browserHistory.push(`/contratar/${prod}`);
    },
    onLink: (event, prod) => {
      if (event) {
        event.preventDefault();
      }
      preventPropagation(event);
      dispatch(selectOptionAction(undefined));
      dispatch(activeMenuAction());
      browserHistory.push(`/seguro/${prod}`);
    },
    onCloseMenu: () => {
      dispatch(activeMenuAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
