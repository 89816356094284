import React from 'react';
import PropTypes from 'prop-types';
import ModalBasic from '../../components/ModalBasic';
import ResponseMessage from '../../components/ResponseMessage';
import CallRequestForm from 'containers/CallRequestForm';

function ModalCallRequest(props) {
  return (
    <div>
      <ModalBasic
        isActive={props.isActive}
        onClose={props.onClose}
        modalContentStyle={{ width: '22rem' }}
      >
        {props.isRequestSended ? (
          <ResponseMessage
            message="Hemos recibido tu solicitud de llamada correctamente"
            buttonText="Aceptar"
            onBtnClick={props.onClose}
          />
        ) : (
          <CallRequestForm
            idForm={props.idForm}
            form="CallRequestForm-Modal"
            onSended={props.markSended}
            privacyPolicyUrl={props.privacyPolicyUrl}
          />
        )}
      </ModalBasic>
    </div>
  );
}

ModalCallRequest.propTypes = {
  isRequestSended: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  markSended: PropTypes.func.isRequired,
};

export default ModalCallRequest;
