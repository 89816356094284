import { createSelector } from 'reselect';

const selectHomeViewDomain = () => (state) => state.get('homeView').toJS();
const selectFormDomain = () => (state) => state.get('form').toJS();

const makeSelectHomeData = () => createSelector(
  selectHomeViewDomain(),
  (substate) => substate.homeData
);

const makeSelectForm = () => createSelector(
  selectFormDomain(),
  (substate) => substate.homeForm
);

export {
  makeSelectHomeData,
  makeSelectForm,
};
