import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { postNet, putNet } from 'utils/networking';
import { isTrackingEnabled } from 'utils/cookies';
import {
  sendNifs,
  sendBankStatement,
  sendFiles,
} from 'containers/FilesUploader/sagas';
import { makeSelectForm } from 'containers/CalculatorPage/selectors';
import { makeSelectBankStatement } from 'containers/FilesUploader/BankStatementUploader/selectors';
import {
  makeSelectExpirationDates,
  makeSelectDocumentsId,
} from 'containers/FilesUploader/NifUploader/selectors';
import ContractModel from '../ApiModels';
import { getInformativeNoteUrl } from '../documentationUtils';
import {
  VALIDATE_CONTRACT_ACTION,
  CONFIRMATE_CONTRACT_ACTION,
  RENEW_PIN_ACTION,
  SEND_DRAFT_ACTION,
} from '../constants';
import {
  nextStepAction,
  cancelConfirmationAction,
  cancelLoadAction,
  setCodeAction,
} from '../actions';
import {
  isValidContractAction,
  renewedPinAction,
} from '../DocAndSignatureView/actions';
import {
  makeSelectBeneficiaries,
  makeSelectPaymentInfo,
  makeSelectPolicyHolderData,
} from '../PolicyHolderView/selectors';
import {
  makeSelectCodes,
  makeSelectIdContract,
} from '../DocAndSignatureView/selectors';
import { setFormFieldAction } from '../InsuredsView/actions';
import { makeSelectInsureds } from '../InsuredsView/selectors';
import { makeSelectHomeData } from '../HomeView/selectors';
import {
  makeSelectProductConfig,
  makeSelectPolicies,
  makeSelectDraftCode,
  makeSelectDraftKey,
  makeSelectPromoterNif,
} from '../selectors';
import {
  getAvailablePinPhones,
  getSourceBusterInfo,
} from '../functions';
import { findAllInRenderedTree } from 'react-addons-test-utils';

function* copyContactDataFromPolicyHolder(insureds, policyHolder) {
  for (let i = 0; i < insureds.length; i += 1) {
    if (!insureds[i].data.mobilePhone) {
      try {
        yield put(
          setFormFieldAction(i, 'mobilePhone', policyHolder.mobilePhone),
        );
      } catch (error) {
        console.log('No se han podido copiar el correo de contacto del padre ');
      }
    }
    if (!insureds[i].data.email) {
      try {
        yield put(setFormFieldAction(i, 'email', policyHolder.email));
      } catch (error) {
        console.log(
          'No se han podido copiar el teléfono de contacto del padre ',
        );
      }
    }
  }
}

function* sendContract() {
  try {
    const beneficiaries = yield select(makeSelectBeneficiaries());
    const paymentInfo = yield select(makeSelectPaymentInfo());
    const policyHolder = yield select(makeSelectPolicyHolderData());
    const productConfig = yield select(makeSelectProductConfig());
    const homeInfo = yield select(makeSelectHomeData());
    const form = yield select(makeSelectForm());
    const policies = yield select(makeSelectPolicies());
    const contractId = yield select(makeSelectIdContract());
    let insureds = yield select(makeSelectInsureds());
    yield call(copyContactDataFromPolicyHolder, insureds, policyHolder);
    insureds = yield select(makeSelectInsureds());
    const promoterNif = yield select(makeSelectPromoterNif());
    const requestModel = new ContractModel({
      policyHolder,
      paymentInfo,
      productConfig,
      form,
      insureds,
      beneficiaries,
      homeInfo,
      policies,
      id: contractId,
    });

    requestModel.Petitions[0].comercialDetails.NifPromoter = promoterNif;

    const response = yield call(postNet, {
      api: 'api/contract/validate',
      body: requestModel,
    });
    yield put(cancelConfirmationAction());
    if (response[0].error.length > 0) {
      yield put(cancelLoadAction());
      alert(response[0].error);
    } else {

      yield call(updateDraft, requestModel);
      yield put(nextStepAction());
      yield put(isValidContractAction(response[0].id, response[0].document));
      if (isTrackingEnabled()) {
        yield call(postNet, {
          api: 'api/tracksource',
          body: getSourceBusterInfo(response[0].id),
        });
      }
    }
  } catch (err) {
    console.error(err);
    alert('Se ha producido un error, vuelva a intentarlo');
    yield put(cancelLoadAction());
  }
}

function* confirmContract() {
  try {
    const idContract = yield select(makeSelectIdContract());
    const codes = yield select(makeSelectCodes());
    const productConfig = yield select(makeSelectProductConfig());
    const form = yield select(makeSelectForm());
    const draftKey = yield select(makeSelectDraftKey());
    const draftCode = yield select(makeSelectDraftCode());
    const informativeNoteUrl = getInformativeNoteUrl(productConfig, form);
    const nifs = Object.keys(codes);
    const confirmations = [];

    for (let i = 0; i < nifs.length; i += 1) {
      confirmations.push({ doc: nifs[i], key: codes[nifs[i]] });
    }

    const response = yield call(postNet, {
      api: 'api/contract/confirmation',
      body: {
        id: idContract,
        url: informativeNoteUrl,
        code: draftCode,
        hashedEmail: draftKey,
        confirmations,
      },
    });
    if (response.status === 1) {
      yield put(push('/final'));
    } else {
      if(!response.message || response.message === "")
      {
        alert("Se ha producido un error al confirmar su contrato. Inténtelo más tarde.");
      }else{
        alert(response.message);
      }
      yield put(cancelConfirmationAction());
    }
  } catch (err) {
    yield put(cancelConfirmationAction());
  }
}

function* renewPin(action) {
  try {
    const body = [];
    const idContract = yield select(makeSelectIdContract());
    const contractData = { idContract, docIds: [] };

    // If no doc then send pin to all insureds, else send only to the selected insured
    if (!action.doc) {
      const insureds = yield select(makeSelectInsureds());
      const policyHolder = yield select(makeSelectPolicyHolderData());
      const availablePhones = getAvailablePinPhones(insureds, policyHolder);
      availablePhones.forEach(phone => {
        contractData.docIds.push(phone.nif);
      });
    } else {
      contractData.docIds.push(action.doc);
    }
    body.push(contractData);

    const result = yield call(putNet, { api: 'api/contract/renewpin', body });
    if (result.status === 1) {
      yield put(renewedPinAction());
    } else {
      if(!result.message || result.message === "")
      {
        alert("Se ha producido un error al enviar el código. Inténtelo más tarde.");
      }else{
        alert(result.message);
      }
      yield put(renewedPinAction());
    }
  } catch (err) {
    console.error(err);
  }
}

function* saveDraft(values) {
  try {
    const form = yield select(makeSelectForm());
    const beneficiaries = yield select(makeSelectBeneficiaries());
    const paymentInfo = yield select(makeSelectPaymentInfo());
    const policyHolder = yield select(makeSelectPolicyHolderData());
    const productConfig = yield select(makeSelectProductConfig());
    const insureds = yield select(makeSelectInsureds());
    const homeInfo = yield select(makeSelectHomeData());
    const policies = yield select(makeSelectPolicies());
    const draftCode = yield select(makeSelectDraftCode());
    const idContract = yield select(makeSelectIdContract());
    const expirationDates = yield select(makeSelectExpirationDates());
    const bankStatement = yield select(makeSelectBankStatement());
    const promoterNif = yield select(makeSelectPromoterNif());

    const hasBankStatement = bankStatement !== undefined;
    let hasExpirationDates = Object.values(expirationDates).length > 0;

    Object.values(expirationDates).forEach(date => {
      if (!date) {
        hasExpirationDates = false;
      }
    });

    if (hasExpirationDates && hasBankStatement) {
      yield call(sendFiles);
    } else if (!hasExpirationDates && hasBankStatement) {
      yield call(sendBankStatement);
    } else if (hasExpirationDates && !hasBankStatement) {
      yield call(sendNifs);
    }

    const contract = new ContractModel({
      policyHolder,
      paymentInfo,
      productConfig,
      form,
      insureds,
      beneficiaries,
      homeInfo,
      policies,
      id: idContract,
    });
    contract.Petitions[0].comercialDetails.nifPromoter = promoterNif;
    contract.docs = [];
    const documents = yield select(makeSelectDocumentsId());
    const docNifs = Object.keys(documents);
    docNifs.forEach(nif => {
      contract.docs.push({
        nif,
        documentId: documents[nif],
      });
    });

    const requestModel = {
      application: contract,
      code: draftCode,
      email: values.email,
    };
    const result = yield call(postNet, {
      api: 'api/contract/save',
      body: requestModel,
    });

    if (result.status === 0) {
      values.onSend(true);
    } else {
      const code = result.message;
      yield put(setCodeAction(code));
      values.onSend();
    }
  } catch (error) {
    console.error(error);
    values.onSend(
      'Ha ocurrido un error al guardar su borrador. Pruebe de nuevo más tarde.',
    );
  }
}

function* updateDraft(requestModel){
  try{
    const draftCode = yield select(makeSelectDraftCode());

    var arr = window.location.href.split("=");
    const requestDraftModel = {
      application: requestModel,
      code: draftCode,
      email: arr[1].replace("&code",""),
    };
    const resultDraft = yield call(postNet, {
      api: 'api/contract/draft/update',
      body: requestDraftModel,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* defaultSaga() {
  yield takeLatest(VALIDATE_CONTRACT_ACTION, sendContract);
  yield takeLatest(CONFIRMATE_CONTRACT_ACTION, confirmContract);
  yield takeLatest(RENEW_PIN_ACTION, renewPin);
  yield takeLatest(SEND_DRAFT_ACTION, saveDraft);
}
