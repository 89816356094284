import { fromJS } from 'immutable';
import {
  SET_HOME_DATA_ACTION,
} from './constants';

const initialState = fromJS({
  homeData: {},
  saveObjects: false,
});

function homeViewReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_DATA_ACTION:
      return state.set('homeData', action.data);
    default:
      return state;
  }
}

export default homeViewReducer;
