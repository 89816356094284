import React from 'react';
import PropTypes from 'prop-types';

function ResponseMessage(props) {
  return (
    <div>
      <div className="u-flex@md">
        <i
          className={`icon u-icon-size-huge ${
            props.error
              ? 'icon--round-minus u-error-color'
              : 'icon--round-check u-approved-color'
          }`}
        />
        <div className="u-flex-align-self-center u-full-width">
          <p
            className={`u-grid-add-horizontal-spacings u-margin-bottom-medium u-font-bold ${
              props.error ? 'u-error-color' : 'u-approved-color'
            }`}
          >
            {props.message}
          </p>
        </div>
      </div>
      <div className={`u-text-center ${props.hideButton ? 'u-hidden' : ''}`}>
        <button
          className="button button--primary u-white-space-normal"
          onClick={props.onBtnClick}
        >
          {' '}
          {props.buttonText ? props.buttonText : 'Cerrar'}
        </button>
      </div>
    </div>
  );
}

ResponseMessage.propTypes = {
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onBtnClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
  hideButton: PropTypes.bool,
};

export default ResponseMessage;
