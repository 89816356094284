import { fromJS } from 'immutable';
import findKey from 'lodash/findKey';
import { getObjectPropertyByPath } from 'utils/functions';
import { getDateFromString, isOfLegalAge } from 'utils/dateFactory';
import healthConditionsConfig from 'config/healthConditions.json';
import config from 'config.json';

const getInsuredPathologies = pathologies => {
  const result = {};
  const keys = Object.keys(pathologies);
  keys.forEach(key => {
    result[key] = pathologies[key].toString();
  });

  return result;
};

const healthConditionPresentAndCommentsIfNeeded = (
  presentHealthConditionForm,
  presentHealthConditionFormValue,
  comments,
) =>
  !presentHealthConditionForm ||
  (presentHealthConditionFormValue === 'true' && !comments);

const getHealthConditionFormValues = healthValues => {
  const healthValuesForm = [];
  healthValues.forEach(healthGroup => {
    healthGroup.list.forEach(healthCond => {
      healthValuesForm.push(healthCond.id);
    });
  });
  return healthValuesForm;
};

const getHealthConditionFormStatus = (healthConditionData, productId, modality) => {
  const product = findKey(config.products, { id: productId.toString() });

  const healthSubtype = config.products[product].modalitiesInfo?.modalitiesGroup
    ?.find(group => group.modalities.some(m => m.modalityName.toLowerCase() === modality.toLowerCase()))
    ?.healthConditionFormSubtype || "default"

  const healthValues =
    healthConditionsConfig[config.products[product].healthConditionForm][healthSubtype];

  const keys = Object.keys(healthConditionData);
  const healthValuesForm = getHealthConditionFormValues(healthValues);

  for (let i = 0; i < healthValuesForm.length; i += 1) {
    if (
      healthConditionPresentAndCommentsIfNeeded(
        keys.indexOf(healthValuesForm[i]) !== -1,
        healthConditionData[healthValuesForm[i]],
        healthConditionData.comments,
      )
    ) {
      return false;
    }
  }

  return true;
};


const IsMultiInsured = (productId) => {
  const product = findKey(config.products, { id: productId.toString() });
  return config.products[product].isMultiInsured;
};

const getRelationship = (insured, productId) => {
  if (!insured.data) return -1;
  let relationshipDraft = insured.data.relationship;
  if (!IsMultiInsured(productId)) { // Si es monoasegurado, relación del asegurado es el tomador
    relationshipDraft = '0';
  }
  if (!isOfLegalAge(getDateFromString(insured.data.birthDate))) {
    return '1';
  }
  return relationshipDraft === '0' ? 'Es el tomador' : relationshipDraft;
};

const getInsuredCoverages = coverages => {
  const result = {};
  if (coverages) {
    const keys = Object.keys(coverages);
    keys.forEach(key => {
      if (coverages[key] === 'Si') {
        result[key] = true;
      }
    });
  }
  return result;
};

const isFirstInsured = index => index === 0;

export const mapDraftData = action => {
  const insureds = [];
  const draftInsureds = getObjectPropertyByPath(
    action.contract,
    'petitions[0].insureds',
  );
  draftInsureds.forEach((insured, i) => {
    const newInsured = {
      isEditing: isFirstInsured(i),
      isEnabled: isFirstInsured(i),
      healthCondition: false,
      showHealthModal: false,
      enabledAddressData: false,
      enabledContactData: true,
      data: {
        lastName: insured.data ? insured.data.surname : undefined,
        genre: insured.data ? insured.data.gender.toString() : undefined,
        height: insured.healthState ? insured.healthState.height : undefined,
        nif: insured.data ? insured.data.nif : undefined,
        name: insured.data ? insured.data.name : undefined,
        mobilePhone: insured.contact ? insured.contact.phone1 : undefined,
        birthDate: insured.data
          ? getDateFromString(insured.data.birthDate)
          : undefined,
        phone: insured.contact ? insured.contact.phone2 : undefined,
        province: insured.address ? insured.address.state : undefined,
        town: insured.address ? insured.address.city : undefined,
        country:
          insured.address && insured.address.countryCode
            ? insured.address.countryCode
            : '724',
        postalCode: insured.address ? insured.address.cp : undefined,
        relationship: getRelationship(
          insured,
          action.contract.petitions[0].product),
        weight: insured.healthState ? insured.healthState.weight : undefined,
        profession: insured.jobDescription
          ? insured.jobDescription.cno
          : undefined,
        nationality: insured.nationalityCode ? insured.nationalityCode : '724',
        email: insured.contact ? insured.contact.email : undefined,
        direction: insured.address ? insured.address.address : undefined,
        ...getInsuredCoverages(insured.coverages),
      },
    };

    if (insured.healthState && insured.healthState.pathologies) {
      newInsured.healthConditionData = getInsuredPathologies(
        insured.healthState.pathologies,
      );
      newInsured.healthConditionData.comments = insured.healthState
        ? insured.healthState.comments
        : undefined;
      newInsured.healthCondition = getHealthConditionFormStatus(
        newInsured.healthConditionData,
        action.contract.petitions[0].product,
        action.contract.petitions[0].insureds[0].coverages.Modalidad
      );
    }

    insureds.push(newInsured);
  });
  return insureds;
};

export default function setLoadedDraft(state, action) {
  return state.set('insureds', fromJS(mapDraftData(action)));
}
