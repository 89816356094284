import moment from 'moment';

export const constructDocs = ({
  files,
  expirationDates,
  documentsId,
  bankStatement,
}) =>
  new Promise((resolve, reject) => {
    try {
      let documents = []; //eslint-disable-line
      let totalBase64Docs = 0;

      if (files) {
        const nifs = Object.keys(files);

        for (let i = 0; i < nifs.length; i += 1) {
          const document = {
            nif: nifs[i],
            expiration: expirationDates[nifs[i]]
              ? moment.utc(expirationDates[nifs[i]], 'DD/MM/YYYY').toDate()
              : undefined,
            type: 10,
            base64Docs: files[nifs[i]][0].fromDraft ? [] : files[nifs[i]],
            documentId: documentsId[nifs[i]],
          };
          documents.push(document);
          totalBase64Docs += document.base64Docs.length;
        }
      }

      if (bankStatement) {
        const documentBank = {
          type: 2020,
          base64Docs: [bankStatement],
        };
        documents.push(documentBank);
        totalBase64Docs += 1;
      }

      if (totalBase64Docs === 0) {
        resolve(documents);
      }

      let readedBase64Docs = 1;
      for (let j = 0; j < documents.length; j += 1) {
        for (let i = 0; i < documents[j].base64Docs.length; i += 1) {
          const reader = new FileReader();
          reader.readAsDataURL(documents[j].base64Docs[i]);
          reader.onload = () => {
            documents[j].base64Docs[i] = reader.result.split(',')[1];
            if (readedBase64Docs === totalBase64Docs) {
              resolve(documents);
            } else {
              readedBase64Docs += 1;
            }
          };
          reader.onerror = error => {
            console.error(error);
            reject(error);
          };
        }
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
