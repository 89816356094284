import makeActionCreator from 'utils/makeActionCreator';
import {
  ADD_INSURED_ACTION,
  DELETE_INSURED_ACTION,
  SET_INSURED_DATA_ACTION,
  HEALTH_CONDITION_MODAL_ACTION,
  ENABLE_EDIT_INSURED_ACTION,
  ENABLE_HEALTH_CONDITION_MODAL_ACTION,
  ENABLE_ADDRESS_DATA_ACTION,
  CHANGE_CONTACT_DATA_ACTION,
  RESET_STATE_INSURED,
  SET_FORM_FIELD_ACTION,
} from './constants';

export const addInsuredAction = makeActionCreator(ADD_INSURED_ACTION);
export const deleteInsuredAction = makeActionCreator(DELETE_INSURED_ACTION, 'position');
export const setInsuredDataAction = makeActionCreator(SET_INSURED_DATA_ACTION, 'values', 'position');
export const enableEditInsuredAction = makeActionCreator(ENABLE_EDIT_INSURED_ACTION, 'position');
export const enableHealthConditionModalAction = makeActionCreator(ENABLE_HEALTH_CONDITION_MODAL_ACTION, 'position');
export const healthConditionModalAction = makeActionCreator(HEALTH_CONDITION_MODAL_ACTION, 'value', 'position', 'data');
export const enableAddressDataAction = makeActionCreator(ENABLE_ADDRESS_DATA_ACTION, 'position');
export const changeContactDataAction = makeActionCreator(CHANGE_CONTACT_DATA_ACTION, 'position', 'value');
export const resetStateInsuredAction = makeActionCreator(RESET_STATE_INSURED, 'product');
export const setFormFieldAction = makeActionCreator(SET_FORM_FIELD_ACTION, 'position', 'field', 'value');

