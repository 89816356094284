import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'tooltip.js';

export class IconHelp extends React.PureComponent {
  // eslint-disable-line
  componentDidMount() {
    new Tooltip(this.tooltip, {
      // eslint-disable-line
      placement: 'top',
      title: this.content.innerHTML,
      html: true,
    });
  }
  render() {
    return (
      <div
        ref={c => {
          this.tooltip = c;
        }}
        className={`tooltip-element u-margin-left-tiny ${this.props.tooltipClassName}`}
        onClick={this.props.preventPropagation}
      >
        <div
          ref={c => {
            this.content = c;
          }}
          className="tooltip-element__content"
        >
          <p dangerouslySetInnerHTML={{ __html: this.props.tooltip }}></p>
        </div>
      </div>
    );
  }
}

IconHelp.propTypes = {
  tooltip: PropTypes.string,
  tooltipClassName: PropTypes.string,
  preventPropagation: PropTypes.func,
};

export default IconHelp;
