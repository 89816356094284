import { fromJS } from 'immutable';
import { SET_LOADED_DRAFT_ACTION } from 'containers/RecoveryContractModal/constants';
import setLoadedDraft from './mapDraft';
import {
  SET_FORM_ACTION,
} from './constants';

const initialState = fromJS({
  form: {},
});

function calculatorPageReducer(state = initialState, action) {
  const stateJs = state.toJS();
  switch (action.type) {
    case SET_FORM_ACTION:
      stateJs.form = action.form;
      return fromJS(stateJs);
    case SET_LOADED_DRAFT_ACTION:
      return setLoadedDraft(state, action);
    default:
      return state;
  }
}

export default calculatorPageReducer;
