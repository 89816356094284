import React from 'react';
import PropTypes from 'prop-types';

function SocialNetwork(props) {
  const strIcon = `icon icon--${props.data.icon} main-footer2__social-item ${
    props.data.mobile ? '' : 'u-only-up-mobile-large'
  }`;

  return (
    <li>
      <a className={strIcon} href={props.data.url} target="_blank">
        <span className="u-sr-only">{props.data.name}</span>
      </a>
    </li>
  );
}

SocialNetwork.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SocialNetwork;
