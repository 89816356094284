import { createSelector } from 'reselect';

const selectInsuredsViewDomain = () => (state) => state.get('insuredsView').toJS();

const makeSelectInsureds = () => createSelector(
  selectInsuredsViewDomain(),
  (substate) => substate.insureds
);

export {
  makeSelectInsureds
};
