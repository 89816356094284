import { call, put, select, takeLatest } from 'redux-saga/effects';
import { postNet } from 'utils/networking';
import { SEND_FILES_ACTION } from 'containers/ContractingPage/constants';
import { renewPinAction } from 'containers/ContractingPage/actions';
import { cancelUploadAction } from 'containers/ContractingPage/DocAndSignatureView/actions';
import { makeSelectIdContract } from 'containers/ContractingPage/DocAndSignatureView/selectors';
import { makeSelectBankStatement } from './BankStatementUploader/selectors';
import {
  makeSelectFilesUploaded,
  makeSelectDocumentsId,
  makeSelectExpirationDates,
} from './NifUploader/selectors';
import { setFilesSendedAction } from './actions';
import { constructDocs } from './functions';

function* sendDocuments(documents) {
  const contract = yield select(makeSelectIdContract());
  const requestModel = {
    idContract: contract,
    docs: documents,
  };

  const response = yield call(postNet, {
    api: 'api/contract/upload',
    body: requestModel,
  });
  return response;
}

function* getNifs() {
  const files = yield select(makeSelectFilesUploaded());
  const documentsId = yield select(makeSelectDocumentsId());
  const expirationDates = yield select(makeSelectExpirationDates());
  return yield call(constructDocs, {
    files,
    expirationDates,
    documentsId,
  });
}

function* getBankStatement() {
  const bankStatement = yield select(makeSelectBankStatement());
  return yield call(constructDocs, {
    bankStatement,
  });
}

function* getAllFiles() {
  const files = yield select(makeSelectFilesUploaded());
  const documentsId = yield select(makeSelectDocumentsId());
  const expirationDates = yield select(makeSelectExpirationDates());
  const bankStatement = yield select(makeSelectBankStatement());
  return yield call(constructDocs, {
    files,
    expirationDates,
    documentsId,
    bankStatement,
  });
}

function* parseResponse(response) {
  if (response.status === 1) {
    yield put(setFilesSendedAction(response.documents));
    return true;
  }
  alert(response.message);
  yield put(cancelUploadAction());
  return false;
}

export function* sendNifs() {
  try {
    const documents = yield call(getNifs);
    const response = yield call(sendDocuments, documents);
    yield call(parseResponse, response);
  } catch (error) {
    console.error(error);
    yield put(cancelUploadAction());
  }
}

export function* sendBankStatement() {
  try {
    const documents = yield call(getBankStatement);
    const response = yield call(sendDocuments, documents);
    yield call(parseResponse, response);
  } catch (error) {
    console.error(error);
    yield put(cancelUploadAction());
  }
}

export function* sendFiles() {
  try {
    const documents = yield call(getAllFiles);
    const response = yield call(sendDocuments, documents);
    return yield call(parseResponse, response);
  } catch (error) {
    console.error(error);
    yield put(cancelUploadAction());
    return false;
  }
}

function* sendFilesAndRenewPin() {
  const responseOk = yield call(sendFiles);
  if (responseOk) {
    yield put(renewPinAction());
  }
}

export function* defaultSaga() {
  yield takeLatest(SEND_FILES_ACTION, sendFilesAndRenewPin);
}
