import makeActionCreator from 'utils/makeActionCreator';
import {
  SHOW_RECOVERY_CONTRACT_MODAL_ACTION,
  HIDE_RECOVERY_CONTRACT_MODAL_ACTION,
  RECOVERY_ERROR_ACTION,
  RECOVERY_REQUIRES_NIF_ACTION,
  LOAD_DRAFT_ACTION,
  PRELOAD_DRAFT_ACTION,
  SET_LOADED_DRAFT_ACTION,
} from './constants';

export const showRecoveryContractModalAction = makeActionCreator(
  SHOW_RECOVERY_CONTRACT_MODAL_ACTION,
);
export const hideRecoveryContractModalAction = makeActionCreator(
  HIDE_RECOVERY_CONTRACT_MODAL_ACTION,
);
export const recoveryErrorAction = makeActionCreator(
  RECOVERY_ERROR_ACTION,
  'error',
);
export const recoveryRequiresNifAction = makeActionCreator(
  RECOVERY_REQUIRES_NIF_ACTION,
  'qparams',
);

export const loadContractDraftAction = makeActionCreator(
  LOAD_DRAFT_ACTION,
  'qparams',
);

export const preLoadContractDraftAction = makeActionCreator(
  PRELOAD_DRAFT_ACTION,
  'qparams',
);

export const setLoadedDraftAction = makeActionCreator(
  SET_LOADED_DRAFT_ACTION,
  'contract',
  'code',
  'key',
);
