import moment from 'moment';

export const getBirthDateFromAge = age => {
  if (age === undefined) return null;
  const birthAge = moment()
    .startOf('day')
    .add(age * -1, 'Y');
  return birthAge.toDate();
};

export const getFormatedBirthDate = birthdate => {
  if (birthdate === undefined) return null;
  return moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const getAge = date => {
  const momentDate = moment(date, 'DD/MM/YYYY');
  return moment().diff(momentDate, 'years');
};

export const isOfLegalAge = birthDate => getAge(birthDate) >= 18;

export const isLessThanAge = (birthDate, age) => getAge(birthDate) < age;

export const getDateFromString = dateString => {
  const momentDate = moment(dateString);
  const formattedDate = momentDate.format('DD/MM/YYYY');
  return formattedDate;
};

export const isLessThanToday = date => {
  const momentDate = moment(date, 'DD/MM/YYYY');
  return momentDate < moment();
};

export const isMoreThanToday = date => {
  const momentDate = moment(date, 'DD/MM/YYYY');  
  return momentDate > moment();
};

