const errorLoading = err => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = cb => componentModule => {
  cb(null, componentModule.default);
};

export function buildRoute(pathRoute, nameRoute, arrayModules) {
  return {
    path: pathRoute,
    name: nameRoute,
    getComponent(nextState, cb) {
      const importModules = Promise.all(arrayModules);
      const renderRoute = loadModule(cb);

      importModules.then(([component]) => {
        renderRoute(component);
      });

      importModules.catch(errorLoading);
    },
  };
}

export function buildRouteSagas(
  pathRoute,
  nameRoute,
  arrayModules,
  injectReducer,
  injectSagas,
) {
  return {
    path: pathRoute,
    name: nameRoute,
    getComponent(nextState, cb) {
      const importModules = Promise.all(arrayModules);
      const renderRoute = loadModule(cb);

      importModules.then(([reducer, sagas, component]) => {
        injectReducer(nameRoute, reducer.default);
        // Podría no ser necesario Sagas en una vista,
        // por lo que el segundo parámetro sería el componente
        if (injectSagas) {
          injectSagas(sagas.default);
          renderRoute(component);
        } else {
          renderRoute(sagas);
        }
      });

      importModules.catch(errorLoading);
    },
  };
}
