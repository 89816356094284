export const ADD_INSURED_ACTION = 'app/ContractingPage/InsuredsView/ADD_INSURED_ACTION';
export const DELETE_INSURED_ACTION = 'app/ContractingPage/InsuredsView/DELETE_INSURED_ACTION';
export const SET_INSURED_DATA_ACTION = 'app/ContractingPage/InsuredsView/SET_INSURED_DATA_ACTION';
export const ENABLE_EDIT_INSURED_ACTION = 'app/ContractingPage/InsuredsView/ENABLE_EDIT_INSURED';
export const ENABLE_HEALTH_CONDITION_MODAL_ACTION = 'app/ContractingPage/InsuredsView/ENABLE_HEALTH_CONDITION_MODAL_ACTION';
export const HEALTH_CONDITION_MODAL_ACTION = 'app/ContractingPage/InsuredsView/HEALTH_CONDITION_MODAL_ACTION';
export const ENABLE_ADDRESS_DATA_ACTION = 'app/ContractingPage/InsuredsView/ENABLE_ADDRESS_DATA_ACTION';
export const CHANGE_CONTACT_DATA_ACTION = 'app/ContractingPage/InsuredsView/CHANGE_CONTACT_DATA_ACTION';
export const RESET_STATE_INSURED = 'app/ContractingPage/InsuredsView/RESET_STATE_INSURED';
export const SET_FORM_FIELD_ACTION = 'app/ContractingPage/InsuredsView/SET_FORM_FIELD_ACTION';

