import React from 'react';
import PropTypes from 'prop-types';

export const renderCheck = ({
  input,
  type,
  placeholder,
  meta: { touched, error },
  privacyPolicyUrl,
}) => (
  <div className={`form-field ${touched && error ? 'has-error' : ''}`}>
    <div className="form-control u-margin-bottom-small">
      <label htmlFor={input.name} className="form-check u-inline">
        <input
          {...input}
          id={input.name}
          type={type}
          className="form-input"
          placeholder={placeholder || ''}
        />
        <span className="form-check__indicator" />
        Certifico que soy mayor de 18 años y autorizo al tratamiento de mis
        datos personales conforme a{' '}
        {privacyPolicyUrl ? (
          <a href={privacyPolicyUrl} target="_blank">
            la política de privacidad
          </a>
        ) : (
          <a href="https://www.divinaseguros.com/pagina/Qv2" target="_blank">
            la política de privacidad
          </a>
        )}
      </label>
    </div>
    {touched && error && <p className="help-block">{error}</p>}
  </div>
);

renderCheck.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.object,
  meta: PropTypes.object.isRequired,
  privacyPolicyUrl: PropTypes.string,
};

export default renderCheck;
