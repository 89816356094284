import sjcl from 'sjcl';
import { browserHistory } from 'react-router';

const hashEmail = email => {
  const bits = sjcl.hash.sha256.hash(email);
  return sjcl.codec.hex.fromBits(bits);
};

export default function addToQuery(values) {
  const key = hashEmail(values.get('email'));
  const code = values.get('code');
  browserHistory.push(`${browserHistory.getCurrentLocation().pathname}?key=${key}&code=${code}`);
}
