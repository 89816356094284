// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import { getAsyncInjectors } from 'utils/asyncInjectors';
import { buildRoute, buildRouteSagas } from 'utils/buildRoute';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars
  return [
    buildRoute('/', 'home', [import('containers/HomePage')]),

    // Product page
    buildRouteSagas(
      '/seguro',
      'productPage',
      [
        import('containers/ProductPage/reducer'),
        import('containers/ProductPage/sagas'),
        import('containers/ProductPage'),
      ],
      injectReducer,
      injectSagas,
    ),
    // Product page
    buildRouteSagas(
      '/seguro/:product',
      'productPage',
      [
        import('containers/ProductPage/reducer'),
        import('containers/ProductPage/sagas'),
        import('containers/ProductPage'),
      ],
      injectReducer,
      injectSagas,
    ),

    // Calculator
    buildRouteSagas(
      '/calculadora/:product',
      'calculatorPage',
      [
        import('containers/CalculatorPage/reducer'),
        import('containers/CalculatorPage'),
      ],
      injectReducer,
      null,
    ),

    // Contracting
    buildRoute('/contratar/:product', 'contractingPage', [
      import('containers/ContractingPage'),
    ]),

    buildRoute('/final', 'thankYouPage', [import('containers/ThankYouPage')]),

    // Medical team
    buildRoute('/cuadromedico', 'medicalTeamPage', [
      import('containers/MedicalTeamPage'),
    ]),
    buildRoute('/talleres', 'medicalTeamPage', [
      import('containers/MedicalTeamPage/Talleres'),
    ]),

    // Offices
    buildRouteSagas(
      '/oficinas',
      'officesPage',
      [
        import('containers/OfficesPage/reducer'),
        import('containers/OfficesPage'),
      ],
      injectReducer,
      null,
    ),

    // FAQs
    buildRouteSagas(
      '/seguro/:product/preguntasfrecuentes',
      'FaqsPage',
      [import('containers/FaqsPage/reducer'), import('containers/FaqsPage')],
      injectReducer,
      null,
    ),

    // Sponsorships
    buildRouteSagas(
      '/patrocinios',
      'SponsorshipHome',
      [
        import('containers/SponsorshipHome/reducer'),
        import('containers/SponsorshipHome'),
      ],
      injectReducer,
      null,
    ),
    buildRouteSagas(
      '/patrocinios/:sponsorshipsection',
      'SponsorshipSection',
      [
        import('containers/SponsorshipSection/reducer'),
        import('containers/SponsorshipSection'),
      ],
      injectReducer,
      null,
    ),
    buildRouteSagas(
      '/patrocinios/:sponsorshipsection/:promotion',
      'SponsorshipPage',
      [
        import('containers/SponsorshipPage/reducer'),
        import('containers/SponsorshipPage'),
      ],
      injectReducer,
      null,
    ),

    //RSC
    buildRouteSagas(
      '/corporativo/responsabilidad-social-corporativa',
      'RSCHome',
      [import('containers/RSCHome/reducer'), import('containers/RSCHome')],
      injectReducer,
      null,
    ),
    buildRouteSagas(
      '/corporativo/responsabilidad-social-corporativa/:rscsection',
      'RSCSection',
      [
        import('containers/RSCSection/reducer'),
        import('containers/RSCSection'),
      ],
      injectReducer,
      null,
    ),

    //Mapa del sitio
    buildRoute('/mapa-del-sitio', 'Sitemap', [import('containers/Sitemap')]),

    //only localhost para crear el sitemap.xml
    //buildRoute('/sitemap', 'sitemap', [import('utils/sitemap')]),
    {
      path: '*',
      name: 'notfound',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
  ];
}
