import { fromJS } from 'immutable';

export const initialStateStage = fromJS({ // eslint-disable-line
  insureds: [
    {
      isEditing: false,
      isEnabled: true,
      healthCondition: true,
      showHealthModal: false,
      enabledAddressData: false,
      enabledContactData: true,
      data: {
        lastName: 'Aranda Lairón',
        genre: '1',
        height: '165',
        nif: '12345678Z',
        name: 'Carlos',
        mobilePhone: '690630300',
        birthDate: '13/05/1990',
        phone: '961234567',
        province: 'Valencia',
        town: 'Valencia',
        country: '724',
        postalCode: '46002',
        relationship: 'Es el tomador',
        weight: '80',
        profession: '3412',
        nationality: '724',
        email: 'correo@mail.com',
        direction: 'Xativa 23',
      },
      healthConditionData: {
        DermatosisSalud: 'false',
        HerniasSalud: 'false',
        FracturasSalud: 'false',
        MiopiaGlaucomaDetalle: 'false',
        DistimiaSalud: 'false',
        PatologiasPsiSalud: 'false',
        CirrosisSalud: 'false',
        HepatitisSalud: 'false',
        EsclerosisSalud: 'false',
        MiopatiasSalud: 'false',
        TraumatismoSalud: 'false',
        CardiopatiaSalud: 'false',
        ArritmiaSalud: 'false',
        TromboSalud: 'false',
        VaricesSalud: 'false',
        BronquitisSalud: 'false',
        AsmaSalud: 'false',
        TumoresSalud: 'false',
        Tumores24meses: 'false',
        CoaguloVIHSalud: 'false',
        PatologiaTiroidea: 'false',
        PatologiaOvarica: 'false',
        DrogasDetalle: 'false',
        AlcoholismoDetalle: 'false',
        CigarrillosDetalle: 'false',
        Discapacidad: 'false',
        EnfermedadGenetica: 'false',
        TratamientoMedicoSalud: 'false',
        IntervencionQuirurgicaSalud: 'false',
      },
    },
  ],
  policy: false,
  policyError: false,
});
