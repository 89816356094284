export const INITIALIZE_CONTRACT_ACTION = 'app/ContractingPage/INITIALIZE_CONTRACT_ACTION';
export const NEXT_STEP_ACTION = 'app/ContractingPage/NEXT_STEP_ACTION';
export const LAST_STEP_ACTION = 'app/ContractingPage/LAST_STEP_ACTION';
export const VALIDATE_CONTRACT_ACTION = 'app/ContractingPage/VALIDATE_CONTRACT_ACTION';
export const CONFIRMATE_CONTRACT_ACTION = 'app/ContractingPage/CONFIRMATE_CONTRACT_ACTION';
export const RENEW_PIN_ACTION = 'app/ContractingPage/RENEW_PIN_ACTION';
export const NEW_LOAD_INSUREDS = 'app/ContractingPage/NEW_LOAD_INSUREDS';
export const CANCEL_CONFIRMATION_ACTION = 'app/ContractingPage/CANCEL_CONFIRMATION_ACTION';
export const RECALCULATE_ACTION = 'app/ContractingPage/RECALCULATE_ACTION';
export const UPDATE_FEE_ACTION = 'app/ContractingPage/UPDATE_FEE_ACTION';
export const CANCEL_LOAD_ACTION = 'app/ContractingPage/CANCEL_LOAD_ACTION';
export const SET_POLICY_ACTION = 'app/ContractingPage/SET_POLICY_ACTION';
export const SET_POLICY_ERROR_ACTION = 'app/ContractingPage/SET_POLICY_ERROR_ACTION';
export const SEND_FILES_ACTION = 'app/ContractingPage/SEND_FILES_ACTION';
export const SEND_DRAFT_ACTION = 'app/ContractingPage/SEND_DRAFT_ACTION';
export const SET_CODE_ACTION = 'app/ContractingPage/SET_CODE_ACTION';

