import React from 'react';
import PropTypes from 'prop-types';

const ModalBasic = props => (
  <div>
    {props.isActive ? (
      <div className="modal is-active">
        <div className="modal__background"></div>
        <div className="modal__content" style={props.modalContentStyle}>
          <div className="basic-modal-content">{props.children}</div>
        </div>
        {props.onClose ? (
          <button className="modal__close" onClick={props.onClose} />
        ) : null}
      </div>
    ) : null}
  </div>
);

ModalBasic.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  modalContentStyle: PropTypes.object,
};

export default ModalBasic;
