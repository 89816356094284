/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import Immutable, { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import { defaultSaga as medicalTeamPageSaga } from 'containers/MedicalTeamPage/sagas';
import { defaultSaga as officeSearchSaga } from 'containers/OfficeSearcher/sagas';
import { defaultSaga as newsViewerSaga } from 'containers/NewsViewer/sagas';
import { defaultSaga as filesUploaderSaga } from 'containers/FilesUploader/sagas';
import ProductPageSaga from 'containers/ProductPage/sagas';
import recoveryContractModalSaga from 'containers/RecoveryContractModal/sagas';
import contractingDefaultSaga from 'containers/ContractingPage/sagas/defaultSagas';
import contractingRecalculateSaga from 'containers/ContractingPage/sagas/recalculateSagas';

import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.REACT_APP_ENVIRONMENT !== 'production' &&
    process.env.REACT_APP_ENVIRONMENT !== 'stage' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: {
            immutable: Immutable,
          },
        })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(medicalTeamPageSaga);
  sagaMiddleware.run(officeSearchSaga);
  sagaMiddleware.run(newsViewerSaga);  
  sagaMiddleware.run(filesUploaderSaga);
  sagaMiddleware.run(recoveryContractModalSaga);
  sagaMiddleware.run(contractingDefaultSaga);
  sagaMiddleware.run(contractingRecalculateSaga);
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
