import { createSelector } from 'reselect';

const recoveryContractModalDomain = () => state =>
  state.get('recoveryContractModal');

const makeSelectValue = value =>
  createSelector(recoveryContractModalDomain(), substate =>
    substate.get(value),
  );

const makeSelectShow = () => makeSelectValue('show');
const makeSelectFinished = () => makeSelectValue('finished');
const makeSelectMessage = () => makeSelectValue('message');
const makeSelectError = () => makeSelectValue('error');
const makeSelectRequiresNif = () => makeSelectValue('requiresNif');
const makeSelectisLoading = () => makeSelectValue('isLoading');
const makeSelectQparams = () => makeSelectValue('qparams');
const makeSelectFromEmail = () => makeSelectValue('fromEmail');

export {
  makeSelectShow,
  makeSelectFinished,
  makeSelectMessage,
  makeSelectError,
  makeSelectRequiresNif,
  makeSelectisLoading,
  makeSelectQparams,
  makeSelectFromEmail,
};
