import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import {
  valrequired,
  valPhone,
} from 'containers/ContractingPage/Forms/FormValidator';

import { onSubmit } from './functions';
import { renderFieldText } from './renderFieldText';
import { renderCheck } from './renderCheck';

class CallRequestForm extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {showSecondInput: false};
}

handleFocus = () => {
  this.setState({ showSecondInput: true });
};

handleBlur = () => {
  this.setState({ showSecondInput: true });
};

  render() {    
    const sending = values => {
      onSubmit(values, this.props.onSended, this.btn);
    };
    return (
      <div className={this.props.className}>
        <form id={this.props.idForm} onSubmit={this.props.handleSubmit(sending)}>
          <div>
            <div className="u-font-h4 u-margin-bottom-small">Te llamamos</div>
          </div>
          <div>
            <Field
              name="name"
              type="text"
              placeholder="Nombre y apellidos"
              component={renderFieldText}
              validate={[valrequired]}
              onFocus={this.handleFocus} onBlur={this.handleBlur}
            />
          </div>
          <div>
            <Field
              name="phone"
              type="text"
              placeholder="Teléfono"
              formInputClassName="is-numeric"
              component={renderFieldText}
              validate={[valrequired, valPhone]}
              onFocus={this.handleFocus} onBlur={this.handleBlur}
            />
          </div>
          <div>
            {(!this.props.hideLegal || this.state.showSecondInput) &&
              <Field
                name={this.props.idForm + "Check"}
                type="checkbox"
                privacyPolicyUrl={this.props.privacyPolicyUrl}
                component={renderCheck}
                validate={[valrequired]}
              />
            }
          </div>
          <div className="u-text-center">
            <button
              ref={c => {
                this.btn = c;
              }}
              className="button button--primary u-white-space-normal"
            >
              Solicitar llamada
            </button>
          </div>
        </form>
      </div>
    );
  }
}

CallRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSended: PropTypes.func,
};

const mapStateToProps = (_, { form }) => ({ form: form || 'CallRequestForm' });

export default compose(
  connect(mapStateToProps),
  reduxForm({ destroyOnUnmount: true, enableReinitialize: true }),
)(CallRequestForm);

