export const INITIALIZE_FILTER_DATA_ACTION = 'app/MedicalTeamPage/INITIALIZE_FILTER_DATA_ACTION';
export const SET_QUERY_PARAMS_ACTION = 'app/MedicalTeamPage/SET_QUERY_PARAMS_ACTION';
export const SET_FILTER_DATA_ACTION = 'app/MedicalTeamPage/SET_FILTER_DATA_ACTION';
export const SET_SORT_ACTION = 'app/MedicalTeamPage/SET_SORT_ACTION';
export const SEARCH_ACTION = 'app/MedicalTeamPage/SEARCH_ACTION';
export const SET_SEARCH_SPECIALIST_ACTION = 'app/MedicalTeamPage/SET_SEARCH_SPECIALIST_ACTION';
export const SET_SEARCH_LOCATION_ACTION = 'app/MedicalTeamPage/SET_SEARCH_LOCATION_ACTION';
export const LOAD_MORE_ACTION = 'app/MedicalTeamPage/LOAD_MORE_ACTION';
export const SET_IS_LOADING_SEARCH_ACTION = 'app/MedicalTeamPage/SET_IS_LOADING_SEARCH_ACTION';
export const SET_IS_LOADING_MORE_ACTION = 'app/MedicalTeamPage/SET_IS_LOADING_MORE_ACTION';
export const SET_SEARCH_RESULT_ACTION = 'app/MedicalTeamPage/SET_SEARCH_RESULT_ACTION';
export const SET_MORE_RESULTS_ACTION = 'app/MedicalTeamPage/SET_MORE_RESULTS_ACTION';
export const SET_FORM_ACTION = 'app/MedicalTeamPage/SET_FORM_ACTION';
export const SET_COORDS_ACTION = 'app/MedicalTeamPage/SET_COORDS_ACTION';

