import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import configProducts from '../../config.json';
import contact from '../../config/contact.config.json';
import configPages from '../../config/pages.config.json';
import configFooter from '../../config/footer.config.json';
import imgAppStore from 'assets/images/btn-app-store.svg';
import imgGooglePlay from 'assets/images/btn-google-play.svg';
import SocialNetWork from '../Footer2/SocialNetwork';
import ProductLink from '../Footer2/ProductLink';
import CallRequestBox from 'containers/CallRequestBox';

function MainFooter2(props) {
  const onOfficesLinkClick = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    browserHistory.push('/oficinas');
  };

  const socialNetworks = configFooter.socialNetworks;
  const families = configProducts.families.seguros;
  const familiesAsegrup = configProducts.families.asegrup;
  const importantProps = props.important ? { target: '_blank' } : {};

  const getPrivacyByLocation = () => {
    if (window.location.pathname.indexOf('/seguro/') > -1) {
      let productSplitted = window.location.pathname.split('/');
      const product = configPages[2];
      return product;
    }
  };
  const avisoLegal = (
    <a href={configFooter.legal} {...importantProps}>
      Aviso legal
    </a>
  );
  const politicaCookies = (
    <a
      className={getPrivacyByLocation() ? 'PoliticaCookies' : 'PoliticaCookies'}
      {...importantProps}
    >
      Política de Cookies
    </a>
  );

  const politicaPrivacidad = (
    <a
      href={
        getPrivacyByLocation() ? getPrivacyByLocation() : configFooter.privacy
      }
      {...importantProps}
    >
      Política de Privacidad
    </a>
  );
  const politicaConsorcio = (
    <a href={configFooter.consorcio} {...importantProps}>
      Nuevas Cláusulas del Consorcio
    </a>
  );
  return (
    <footer>
      <div
        className={`main-footer2 ${
          props.important ? 'main-footer2--minimalist' : ''
        } ${props.private ? 'main-footer2--private' : ''}`}
      >
        <div className="main-footer2__body">
          <div className="main-footer2__content">
            <div className="row is-not-important is-not-private">
              <div className="col-12 col-md-5">
                <div className="row">
                  {props.enterprise && props.enterprise === 'Asegrup' ? (
                    <div className="col main-footer2__menu">
                      <div className="main-footer2__menu-title main-footer2__title u-margin-bottom-small">
                        <span className="main-footer2__title">Asegrup</span>{' '}
                        <span className="main-menu__arrow" />
                      </div>
                      <div className="main-footer2__menu-content">
                        <ul className="list list--brand-color u-margin-bottom">
                          {familiesAsegrup.map((myfam, i) =>
                            myfam.products.map((prod, j) => (
                              <ProductLink key={j} data={prod} />
                            )),
                          )}
                        </ul>
                        <div className="main-footer2__title u-margin-bottom-small">
                          <span className="main-footer2__title">
                            Divina Seguros
                          </span>
                        </div>
                        <ul className="list list--brand-color u-margin-bottom">
                          <li>
                            <a href="/">Seguros</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="col main-footer2__menu u-only-up-desktop">
                      <div className="main-footer2__menu-title main-footer2__title u-margin-bottom-small u-margin-bottom@md">
                        <a href="/seguro">
                          <span className="main-footer2__title">
                            Nuestros seguros
                          </span>
                        </a>{' '}
                        <span className="main-menu__arrow" />
                      </div>
                      <div className="main-footer2__menu-content">
                        <ul className="u-margin-bottom">
                          {families.map((myfam, i) =>
                            myfam.products.map((prod, j) => (
                              <ProductLink key={j} data={prod} />
                            )),
                          )}
                          <li>
                            <a href={configFooter.correduriasInsurance}>
                              Seguros para corredurías
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="visible-xs-block col-xs-12 col-md-12 u-margin-bottom@u-only-desktop"></div>
                  {props.enterprise && props.enterprise === 'Asegrup' ? (
                    ''
                  ) : (
                    <div className="col main-footer2__menu">
                      <div className="main-footer2__menu-title main-footer2__title u-margin-bottom-small u-margin-bottom@md">
                        <span className="main-footer2__title">Conócenos</span>{' '}
                        <span className="main-menu__arrow" />
                      </div>
                      <div className="main-footer2__menu-content u-margin-bottom-small u-margin-bottom@md">
                        <ul className="">
                          <li>
                            <a href={configFooter.corporate}>¿Quiénes somos?</a>
                          </li>
                          <li>
                            <a href={configFooter.blog}>
                              Blog Consejos Divinos
                            </a>
                          </li>
                          <li>
                            <a href={configFooter.sponsorships}>Patrocinios</a>
                          </li>
                          <li>
                            <a href={configFooter.rsc}>RSC</a>
                          </li>
                          <li>
                            <a href={configFooter.humanResources}>
                              Trabaja con nosotros
                            </a>
                          </li>
                          <li>
                            <a href={configFooter.areaPrensa}>Área de Prensa</a>
                          </li>
                          <li>
                            <a href={configFooter.promotions}>
                              Bases legales promociones
                            </a>
                          </li>
                          <li>
                            <a href={configFooter.asegrupInsurance}>
                              Seguros Asegrup
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="main-footer2__menu-title main-footer2__title u-margin-bottom-small u-margin-bottom@md">
                        <span className="main-footer2__title">
                          <a href={configFooter.canalEtico} target="_blank">
                            <span className="main-footer2__title">
                              Canal Ético
                            </span>
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                  {/* <div className="col main-footer2__menu"></div> */}

                  <div className="visible-xs-block col-xs-12 col-md-12 u-margin-bottom@u-only-desktop"></div>
                  <div className="col main-footer2__menu u-only-mobile">
                    <div className="main-footer2__menu-title main-footer2__title u-margin-bottom-small u-margin-bottom@md">
                      <span className="main-footer2__title">Legal</span>{' '}
                      <span className="main-menu__arrow" />
                    </div>
                    <div className="main-footer2__menu-content">
                      <ul className="">
                        <li>{avisoLegal}</li>
                        <li>{politicaCookies}</li>
                        <li>{politicaPrivacidad}</li>
                        <li>{politicaConsorcio}</li>
                        <li>
                          <a href="/mapa-del-sitio">Mapa del sitio</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col u-only-up-desktop">
                    <div className="main-footer2__title u-margin-bottom-small u-margin-bottom@md">
                      Contáctanos
                    </div>
                    <div className="row u-margin-bottom-small">
                      <div className="col-12">
                        <a
                          href="tel:+34963113340"
                          className="link__contact u-font-bold u-text-decoration-none is-numeric"
                        >
                          <span className="icon icon--phone-solid icon__round" />
                          {contact.mainPhone}
                        </a>
                      </div>
                    </div>
                    <div className="row u-margin-bottom-small">
                      <div className="col-12">
                        <span className="link__contact u-font-bold">
                          <span className="icon icon--form icon__round" />
                          <CallRequestBox
                            displayCustomLink
                            className="link__contact u-font-bold"
                            customText="Formulario contacto"
                            url={politicaPrivacidad}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <a
                          href="/"
                          className="link__contact u-font-bold"
                          onClick={onOfficesLinkClick}
                        >
                          <span className="icon icon--location-solid icon__round" />
                          Oficinas cercanas
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12 text-right u-text-center@only-mobile u-margin-top@only-mobile">
                        <p className="main-footer2__title u-margin-right-small u-margin-bottom-small">
                          Descarga nuestra App
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 u-margin-bottom@md u-text-center@only-mobile">
                        <a
                          className="u-inline-block u-margin-right-small u-margin-bottom-small main-footer2__social-item"
                          href={configFooter.app.appStore}
                          target="_blank"
                        >
                          <img
                            src={imgAppStore}
                            alt="Disponible en la App Store"
                          />
                        </a>

                        <a
                          className="u-inline-block u-margin-bottom-small main-footer2__social-item"
                          href={configFooter.app.playStore}
                          target="_blank"
                        >
                          <img
                            src={imgGooglePlay}
                            alt="Disponible en Google Play"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12 text-right u-text-center@only-mobile u-margin-top@only-mobile">
                        <p className="main-footer2__title u-margin-right-small u-margin-bottom-small">
                          Nuestras redes sociales
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <ul className="main-footer2__social-list is-not-important">
                          {socialNetworks.map((social, i) => (
                            <SocialNetWork key={i} data={social} />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`main-footer2__legal ${
          props.important ? 'up-mobile-footer2-important' : 'up-mobile-footer2'
        } `}
      >
        <div className="main-footer2__legal_body">
          <div className="row">
            <div
              className={`${
                props.important ? 'col-6' : 'col-12'
              } col-md-8 u-only-up-desktop`}
            >
              {avisoLegal} |&nbsp;
              {politicaCookies} |&nbsp;
              {politicaPrivacidad} |&nbsp;
              {politicaConsorcio} |&nbsp;
              <a href="/mapa-del-sitio">Mapa del sitio</a>
            </div>
            <div className="col-12 col-md-4 main-footer2__legal_areas u-text-right@md is-not-important is-not-private">
              <span>
                <a
                  className="link--icon u-margin-right"
                  href={configFooter.webMediadores}
                  target="_blank"
                >
                  <span className="link__icon icon icon--padlock-solid" />
                  Zona mediadores
                </a>
              </span>
              <span>
                <a
                  className="link--icon"
                  href={configFooter.webProveedores}
                  target="_blank"
                >
                  <span className="link__icon icon icon--padlock-solid" />
                  Zona Proveedores
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

MainFooter2.propTypes = {
  important: PropTypes.bool.isRequired,
  private: PropTypes.bool,
};

export default MainFooter2;
