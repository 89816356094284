import contractingConfig from 'config/contracting.config.json';
import { getAge } from 'utils/dateFactory';
import { getObjectPropertyByPath } from 'utils/functions';
import sbjs from 'sourcebuster';
import find from 'lodash/find';

export const getMensualFee = (productConfig, form, result) => {
  const modalitiesInfo = productConfig.modalitiesInfo;
  let total = 0;

  if (productConfig.price && !Array.isArray(productConfig.price)) {
    return productConfig.price;
  }

  if (
    modalitiesInfo &&
    !productConfig.calculator.selectorResult &&
    modalitiesInfo.modalitiesGroup
  ) {
    total += form.selectedModalityTotalPrice;
  }

  if (modalitiesInfo && modalitiesInfo.addons) {
    for (let i = 0; i < modalitiesInfo.addons.length; i += 1) {
      if (form[modalitiesInfo.addons[i].id]) {
        total += form[modalitiesInfo.addons[i].id];
      }
    }
  }

  if (productConfig.calculator.type === 'selector' && !modalitiesInfo) {
    total += form[productConfig.calculator.form.formName];
  }

  if (
    productConfig.calculator.type === 'selector' &&
    modalitiesInfo &&
    modalitiesInfo.modalitiesGroup
  ) {
    const selectedOption = form[productConfig.calculator.form.formName];
    let position = 0;
    for (
      let i = 0;
      i < modalitiesInfo.modalitiesGroup[0].modalities.length;
      i += 1
    ) {
      if (
        modalitiesInfo.modalitiesGroup[0].modalities[i].modality.toString() ===
        selectedOption.toString()
      ) {
        position = i;
      }
    }
    total += result[position].price;
  }
  return total;
};

export const getContractingStepsByProduct = product => {
  const totalSteps = contractingConfig.steps;
  const visibleSteps = [];

  for (let i = 0; i < totalSteps.length; i += 1) {
    if (
      !totalSteps[i].products ||
      totalSteps[i].products.filter(prod => prod === product).length
    ) {
      visibleSteps.push(totalSteps[i]);
    }
  }
  return visibleSteps;
};

export const getStepsTitles = steps => {
  const titles = [];
  steps.forEach(step => {
    titles.push(step.name);
  }, this);
  return titles;
};

export const checkPolicies = policies => {
  const policiesConfig = contractingConfig.policy;
  for (let i = 0; i < policiesConfig.length; i += 1) {
    if (policiesConfig[i].mandatory) {
      const policy = find(policies, { id: policiesConfig[i].id });
      if (!policy || !policy.value) {
        return false;
      }
    }
  }
  return true;
};

export const getPaymentPeriodName = paymentValue => {
  for (let i = 0; i < contractingConfig.paymentPeriods.length; i += 1) {
    const payment = contractingConfig.paymentPeriods[i];
    if (payment.value == paymentValue) {
      return payment.text;
    }
  }
  return '-';
};

export const isValidInsureds = insureds => {
  for (let i = 0; i < insureds.length; i += 1) {
    if (insureds[i].isEditing) {
      return false;
    }
  }
  return true;
};

export const getAvailablePinPhones = (insureds, policyHolder) => {
  const resultPhones = [
    {
      name: policyHolder.name,
      nif: policyHolder.nif,
      phone: policyHolder.mobilePhone,
    },
  ];
  for (let i = 0; i < insureds.length; i += 1) {
    const insuredData = insureds[i].data;
    if (
      insuredData.nif !== policyHolder.nif &&
      getAge(insuredData.birthDate) >= 18
    ) {
      resultPhones.push({
        name: insuredData.name,
        nif: insuredData.nif,
        phone: insuredData.mobilePhone,
      });
    }
  }

  return resultPhones;
};

const arePinPhonesFilled = (insureds, policyHolder, codes) => {
  if (!codes) {
    return false;
  }
  const nifs = Object.keys(codes);
  if (getAvailablePinPhones(insureds, policyHolder).length === nifs.length) {
    for (let i = 0; i < nifs.length; i += 1) {
      if (codes[nifs[i]] === undefined || codes[nifs[i]].length !== 6) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

const personalDataHaveNif = (data) => (data && data.nif != null && data.nif !== '');

const someInsuredsHaveNif = (insureds) => {
  for (let i = 0; i < insureds.length; i += 1) {
    if (personalDataHaveNif(insureds[i].data)) {
      return true;
    }
  }
  return false;
};

export const isSaveDraftButtonDisabled = (props) =>
  (!someInsuredsHaveNif(props.insureds) && !personalDataHaveNif(props.policyHolderData))
  && props.step < 2;

export const isSaveDraftButtonVisible = (props) => (props && props.draft ? props.draft : false);

export const isContinueButtonDisabled = (currentStepId, props) => {
  switch (currentStepId) {
    case 'insureds':
      return !isValidInsureds(props.insureds);
    case 'policyHolder':
      return props.isEditingPolicyHolder || props.isEditingPaymentInfo || props.isEditingBeneficiaries;
    case 'docAndSignature':
      return !arePinPhonesFilled(
        props.insureds,
        props.policyHolderData,
        props.codes,
      );
    default:
      return true;
  }
};

export const getSourceBusterInfo = id => {
  return { id, first: sbjs.get.first, current: sbjs.get.current };
};


const getTotalDataInResponse = (props) => {
  let totalDataInResponse = 0;
  for (let i = 0; i < props.productConfig.modalitiesInfo.modalitiesGroup.length; i += 1) {
    totalDataInResponse += props.productConfig.modalitiesInfo.modalitiesGroup[i].modalities.length;
  }
  return totalDataInResponse;
};

export const getModalityPrice = (props) => {
  let total = 0;

  // Get the total number of modalities
  const totalDataInResponse = getTotalDataInResponse(props);

  // Group the response by the number of modalities
  const totalResponses = props.response.length / totalDataInResponse;

  // Get the modalities number in the current group
  const responseModalityGroups = props.productConfig.modalitiesInfo.modalitiesGroup[props.modGrpIndex].modalities.length;

  for (let i = 0; i < totalResponses; i += 1) {
    const response = props.response[props.modIndex + (props.modGrpIndex * responseModalityGroups) + (totalDataInResponse * i)];
    if (response.status === 0) return 0;

    let price = 0;
    if (Array.isArray(response.price)) { // The price could be an array
      for (let j = 0; j < response.price.length; j += 1) {
        price += response.price[j];
      }
    } else {
      price += response.price;
    }
    // Normalizes the price if needed
    if (props.productConfig.modalitiesInfo.normalizePrice) {
      total += price / props.productConfig.modalitiesInfo.normalizePrice;
    } else {
      total += price;
    }
  }
  return total;
};

export const isPolicyHolderAnInsured = insureds => {
  for (let i = 0; i < insureds.length; i += 1) {
    if (insureds[i].data.relationship === 'Es el tomador') {
      return true;
    }
  }
  return false;
};

function isModalitySelected(modality) {
  return modality.modalityName === this.selectedModality;
}

function productHasModalities(product) {
  return product.modalitiesInfo;
}

function deleteToolTipIfNecessary(name) {
  if (name && name.indexOf('<') !== -1) {
    return name.slice(0, name.indexOf('<'));
  }
  return name;
}

function getModalityNameSelectedFromProductModalities(calculatorForm, productModalitiesGroup) {
  const modalitiesInGroup = productModalitiesGroup[calculatorForm.selectedModalityGrp].modalities;
  const modalityName = modalitiesInGroup.filter(isModalitySelected, calculatorForm)[0].name;
  return deleteToolTipIfNecessary(modalityName);
}

export function buildModalityNameToShow(calculatorForm, product) {
  let modalityNameToShow = '';
  if (!productHasModalities(product)) {
    return product.productInfo.name;
  }

  if (getObjectPropertyByPath(calculatorForm, 'selectedModalityGrp') >= 0) {
    modalityNameToShow = getModalityNameSelectedFromProductModalities(calculatorForm,
      product.modalitiesInfo.modalitiesGroup);
  }

  if (!getObjectPropertyByPath(product, 'modalitiesInfo.addons')) {
    return modalityNameToShow;
  }

  product.modalitiesInfo.addons.forEach(addon => {
    if (calculatorForm[addon.id]) {
      modalityNameToShow += ` + ${addon.name}`;
    }
  });

  return modalityNameToShow;
}
