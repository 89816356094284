import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import {
  valrequired,
  valEmail,
  valnif,
} from 'containers/ContractingPage/Forms/FormValidator';
import { renderFieldText } from './renderFieldText';

const RecoveryForm = props => (
  <form onSubmit={props.handleSubmit}>
    {props.requiresNif ? (
      <div>
        <p className="u-margin-bottom">Para poder recuperar esta solicitud se necesita el DNI del asegurado o del tomador</p>
        <Field
          name="nif"
          type="text"
          placeholder="DNI/NIE"
          component={renderFieldText}
          validate={[valrequired, valnif]}
        />
      </div>
    ) : (
      <div>
        <Field
          name="email"
          type="text"
          placeholder="Correo electrónico"
          component={renderFieldText}
          validate={[valrequired, valEmail]}
        />
        <Field
          name="code"
          type="text"
          placeholder="Código"
          component={renderFieldText}
          validate={valrequired}
        />
      </div>
      )
    }
    <div className="u-text-center">
      <button
        className={`button button--primary${
          props.isLoading ? ' is-loading' : ''
          }`}
      >
        {props.requiresNif ? 'Continuar' : 'Cargar datos de mi solicitud'}
      </button>
    </div>
  </form>
);

RecoveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  requiresNif: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: 'recoveryContract',
})(RecoveryForm);
