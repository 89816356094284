import { fromJS } from 'immutable';
import { getObjectPropertyByPath } from 'utils/functions';
import { getDateFromString } from 'utils/dateFactory';

const setPolicyHolderData = (state, policyHolder) => {
  const data = {
    lastName: policyHolder.data.surname,
    genre: policyHolder.data.gender.toString(),
    nif: policyHolder.data.nif,
    name: policyHolder.data.name,
    mobilePhone: policyHolder.contact.phone1,
    birthDate: getDateFromString(policyHolder.data.birthDate),
    phone: policyHolder.contact.phone2,
    province: policyHolder.address.state,
    town: policyHolder.address.city,
    country: policyHolder.address.countryCode,
    postalCode: policyHolder.address.cp,
    profession: policyHolder.jobDescription.cno,
    nationality: policyHolder.nationalityCode,
    email: policyHolder.contact.email,
    direction: policyHolder.address.address,
  };

  return state.set('data', fromJS(data));
};

const setPaymentData = (state, paymentDetails, effectDate) => {
  let account = paymentDetails.iban ? paymentDetails.iban.replace('ES', '') : '';
  account = paymentDetails.Iban ? paymentDetails.Iban.replace('ES', '') : account;
  let paymentPeriod = paymentDetails.frequency ? paymentDetails.frequency.toString() : undefined;
  paymentPeriod = paymentDetails.Frequency ? paymentDetails.Frequency.toString() : paymentPeriod;

  let startDatePostponedMonths = 1;
  if (effectDate)
  {
    const diffMonths = monthDiff(new Date(), new Date(effectDate))
    startDatePostponedMonths = Math.min(1, diffMonths)
  }
  return state.set('paymentInfo', fromJS({ account, paymentPeriod, startDatePostponedMonths }));
};

const isPolicyHolderAnInsured = (contract) => {
  const insureds = getObjectPropertyByPath(contract, 'petitions[0].insureds');
  return insureds.some(insured => insured.isPolicyHolder);
};

const setPaymentFormStatus = (state) => {
  let newState = state;
  const paymentData = state.get('paymentInfo');
  if (paymentData.get('account') && paymentData.get('paymentPeriod')) {
    newState = newState.set('isEditingPaymentInfo', false);
  } else {
    newState = newState.set('isEditingPaymentInfo', true);
  }
  return newState;
};

function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() + 
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
 };

export default function setLoadedDraft(state, action) {
  const policyHolder = action.contract.policyHolder;
  const paymentDetails = getObjectPropertyByPath(action.contract, 'petitions[0].paymentDetails');
  const insureds = getObjectPropertyByPath(action.contract, 'petitions[0].insureds');
  const beneficiaries = insureds[0].coverages.Beneficiarios;
  let nextState = state;
  if (policyHolder) {
    nextState = setPolicyHolderData(nextState, policyHolder);
  }
  if (paymentDetails) {
    const effectDate = action.contract.petitions[0].effectDate;
    nextState = setPaymentData(nextState, paymentDetails, effectDate);
    nextState = setPaymentFormStatus(nextState);
  }
  if (isPolicyHolderAnInsured(action.contract)) {
    nextState = nextState.merge({ isPolicyHolderAnInsured: true });
  } else {
    nextState = nextState.merge({ isPolicyHolderAnInsured: false, isEditing: true });
  }
  if (beneficiaries) {
    nextState = nextState.merge({ beneficiariesInfo: { beneficiaries } });
  }

  return nextState;
}
