import { call, put, takeLatest } from 'redux-saga/effects';
import find from 'lodash/find';
import config from 'config.json';
import findKey from 'lodash/findKey';
import { push } from 'react-router-redux';
import { getNet } from 'utils/networking';
import { browserHistory } from 'react-router';
import { setFilesFromDraftAction } from 'containers/FilesUploader/actions';
import { recalculateAction } from 'containers/ContractingPage/actions';
import { LOAD_DRAFT_ACTION, PRELOAD_DRAFT_ACTION } from './constants';
import {
  recoveryErrorAction,
  recoveryRequiresNifAction,
  setLoadedDraftAction,
} from './actions';

const nifIsRequired = response =>
  response.errors &&
  find(response.errors, { description: 'isRequired', path: 'nif' });

const agentIsNotAllowed = response =>
  response.errors &&
  find(response.errors, {
    path: 'agentNotAllowed',
  });

function* loadedDraft({ response, code, hashedEmail }) {
  yield put(setLoadedDraftAction(response, code, hashedEmail));
  if (response.docs) {
    yield put(setFilesFromDraftAction(response.docs));
  }
  const product = findKey(config.products, {
    id: response.petitions[0].product.toString(),
  });
  yield put(
    push(`/contratar/${product}${browserHistory.getCurrentLocation().search}`),
  );
  yield put(recalculateAction(config.products[product]));
}

function* callError() {
  yield put(
    recoveryErrorAction([
      {
        description: 'No se ha podido recuperar la solicitud',
      },
    ]),
  );
  yield put(push(browserHistory.getCurrentLocation().pathname));
}

function* showError(error) {
  yield put(
    recoveryErrorAction([
      {
        description: error,
      },
    ]),
  );
  yield put(push(browserHistory.getCurrentLocation().pathname));
}

function* draftNotFound() {
  yield call(
    showError,
    'No se ha encontrado ningún contrato asociado al email indicado.',
  );
}

function* agentNotAllowed(data) {
  yield call(
    showError,
    `Para continuar con el proceso de contratación te recomendamos que contactes con ${
      data.name
    } en el ${data.phone}`,
  );
}

function* preLoadDraft(action) {
  try {
    const response = yield call(getNet, {
      api: 'api/contract/load',
      qparams: action.qparams,
    });

    if (response) {
      const agentNotAllowedResult = agentIsNotAllowed(response);
      if (nifIsRequired(response)) {
        yield put(recoveryRequiresNifAction(action.qparams));
      } else if (agentNotAllowedResult) {
        yield call(agentNotAllowed, agentNotAllowedResult.description);
      } else {
        yield call(loadedDraft, {
          response,
          code: action.qparams.code,
          hashedEmail: action.qparams.key,
        });
      }
    } else {
      yield call(draftNotFound);
    }
  } catch (error) {
    console.error(error);
    yield call(callError);
  }
}

function* loadDraft(action) {
  try {
    const response = yield call(getNet, {
      api: 'api/contract/load',
      qparams: action.qparams,
    });
    if (response) {
      if (response.errors) {
        yield put(
          recoveryErrorAction([
            {
              description:
                'No se ha encontrado ningún contrato asociado al DNI/NIE indicado.',
            },
          ]),
        );
        yield put(push(browserHistory.getCurrentLocation().pathname));
      } else {
        yield call(loadedDraft, {
          response,
          code: action.qparams.code,
          hashedEmail: action.qparams.hashedEmail,
        });
      }
    } else {
      yield call(draftNotFound);
    }
  } catch (error) {
    yield call(callError, error);
  }
}

function* defaultSaga() {
  yield takeLatest(LOAD_DRAFT_ACTION, loadDraft);
  yield takeLatest(PRELOAD_DRAFT_ACTION, preLoadDraft);
}

export default defaultSaga;
