import { fromJS } from 'immutable';
import config from 'config.json';
import {
  SET_FAMILY_ACTION,
  SET_PRODUCT_ACTION,
  INI_COMBOS_CALCULATOR_ACTION,
} from './constants';

const initialState = fromJS({
  productSelected: 'salud',
  familySelected: 0,
});

const getArrayFamilies = () => {  
  const result = [];
  const cloneFams = [...config.families.seguros];

  cloneFams.sort((a, b) => (b.productSearchOrder < a.productSearchOrder ? 1 : -1));
  for (let i = 0; i < cloneFams.length; i += 1) {
    const fam = cloneFams[i];
    const familyProducts = getArrayProducts(cloneFams, i);    
    if (familyProducts.length > 0) {
      result.push({
        title: fam.title,
        position: i,
        products: familyProducts,
        order: fam.productSearchOrder,
      });
    }
  }
  return result;
};

const getArrayProducts = (cloneFams, family) => {
  const result = [];
  for (let i = 0; i < cloneFams[family].products.length; i += 1) {
    const product = cloneFams[family].products[i].path;
    result.push({
      title: config.products[product].productInfo.name,
      path: cloneFams[family].products[i].path,
    });
  }

  return result;
};

const productSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case INI_COMBOS_CALCULATOR_ACTION: {
      return state.set('familySelect', getArrayFamilies());
    }
    case SET_FAMILY_ACTION: {
      const cmb = state.get('familySelect');
      return state.merge({
        familySelected: action.family,
        productSelect: cmb[action.family].products,
        productSelected: cmb[action.family].products[0].path,
      });
    }
    case SET_PRODUCT_ACTION:
      return state.set('productSelected', action.product);
    default:
      return state;
  }
};

export default productSearchReducer;
