import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getNet } from 'utils/networking';
import {
  makeSelectSortType,
  makeSelectSearchResult,
  makeSelectForm,
  makeSelectCoords,
} from './selectors';
import {
  INITIALIZE_FILTER_DATA_ACTION,
  SEARCH_ACTION,
  LOAD_MORE_ACTION,
  SET_SORT_ACTION,
} from './constants';
import {
  setFilterDataAction,
  setIsLoadingSearchAction,
  setIsLoadingMoreAction,
  setSearchResultAction,
  setMoreResultAction,
  setFormAction,
} from './actions';
import { buildFilterDataMap } from './functions';

function* initializeFilterData(action) {
  try {
    const response = yield call(getNet, {
      api: 'api/medicalteam/source',
      header: {},
      id: '',
    });
    const filterDataMap = buildFilterDataMap(response);

    yield put(setFilterDataAction(filterDataMap));
    if (action.callback) {
      action.callback(filterDataMap, action.params);
    }
  } catch (ex) {}
}

function* search(data) {
  try {
    const sort = yield select(makeSelectSortType());
    const coords = yield select(makeSelectCoords());
    const form = data.values;

    const qparams = {
      medicalTeamCode: form.team,
      medicalTeamTypeId: form.product,
      specialityId: form.specialist,
      provinceTownPostalCode: form.location,
      nameOrMedicalCenter: form.name,
      latitude: coords ? coords.latitude : undefined,
      longitude: coords ? coords.longitude : undefined,
      sort,
      postalCode: form.postalCode,
      town: form.town,
      province: form.province,
      southWestLatitude: form.southWestLatitude,
      southWestLongitude: form.southWestLongitude,
      northEastLatitude: form.northEastLatitude,
      northEastLongitude: form.northEastLongitude,
    };

    const response = yield call(getNet, {
      api: 'api/medicalteam/search',
      qparams,
    });
    yield put(setFormAction(form));
    yield put(setSearchResultAction(response));
  } catch (e) {
    put(setIsLoadingSearchAction(false));
  }
}

function* searchNewSort() {
  try {
    const sort = yield select(makeSelectSortType());
    const form = yield select(makeSelectForm());
    const coords = yield select(makeSelectCoords());
    const qparams = {
      medicalTeamCode: form.team,
      medicalTeamTypeId: form.product,
      specialityId: form.specialist,
      provinceTownPostalCode: form.location,
      nameOrMedicalCenter: form.name,
      latitude: coords ? coords.latitude : undefined,
      longitude: coords ? coords.longitude : undefined,
      sort,
      postalCode: form.postalCode,
      town: form.town,
      province: form.province,
      southWestLatitude: form.southWestLatitude,
      southWestLongitude: form.southWestLongitude,
      northEastLatitude: form.northEastLatitude,
      northEastLongitude: form.northEastLongitude,
    };

    const response = yield call(getNet, {
      api: 'api/medicalteam/search',
      qparams,
    });
    yield put(setFormAction(form));
    yield put(setSearchResultAction(response));
  } catch (e) {
    put(setIsLoadingSearchAction(false));
  }
}

function* loadMore() {
  try {
    const form = yield select(makeSelectForm());
    const sort = yield select(makeSelectSortType());
    const results = yield select(makeSelectSearchResult());
    const coords = yield select(makeSelectCoords());

    const qparams = {
      medicalTeamCode: form.team,
      medicalTeamTypeId: form.product,
      specialityId: form.specialist,
      provinceTownPostalCode: form.location,
      nameOrMedicalCenter: form.name,
      latitude: coords ? coords.latitude : undefined,
      longitude: coords ? coords.longitude : undefined,
      sort,
      offset: results.length,
      postalCode: form.postalCode,
      town: form.town,
      province: form.province,
      southWestLatitude: form.southWestLatitude,
      southWestLongitude: form.southWestLongitude,
      northEastLatitude: form.northEastLatitude,
      northEastLongitude: form.northEastLongitude,
    };

    const response = yield call(getNet, {
      api: 'api/medicalteam/search',
      qparams,
    });
    yield put(setMoreResultAction(response));
  } catch (e) {
    yield setIsLoadingMoreAction(false);
  }
}

export function* defaultSaga() {
  yield takeLatest(INITIALIZE_FILTER_DATA_ACTION, initializeFilterData);
  yield takeLatest(SEARCH_ACTION, search);
  yield takeLatest(SET_SORT_ACTION, searchNewSort);
  yield takeLatest(LOAD_MORE_ACTION, loadMore);
  yield take(LOCATION_CHANGE);
}

export default [defaultSaga];
