import { fromJS } from 'immutable';
import { getObjectPropertyByPath } from 'utils/functions';
import { SET_LOADED_DRAFT_ACTION } from 'containers/RecoveryContractModal/constants';
import {
  INITIALIZE_ACTION,
  SHOW_MODAL_ACTION,
  ACCEPT_CONDITIONS_ACTION,
  SET_CODE_ACTION,
  IS_VALID_CONTRACT_ACTION,
  RENEWED_PIN_ACTION,
  IS_PDF_RENDER,
  CANCEL_UPLOAD_ACTION,
} from './constants';

import { SEND_FILES_ACTION } from '../constants';

const setLoadedDraft = (state, action) => {
  const idContract = getObjectPropertyByPath(
    action.contract,
    'petitions[0].id',
  );
  if (idContract) {
    return state.set('idContract', idContract);
  }
  return state;
};

const initialState = fromJS({
  showModal: undefined,
  acceptedConditions: [],
  sendedPin: false,
  buttonConditions: {
    text: undefined,
    render: false,
  },
  codes: {},
});

function docAndSignatureViewReducer(state = initialState, action) {
  const stateJs = state.toJS();
  switch (action.type) {
    case INITIALIZE_ACTION:
      return initialState;
    case SHOW_MODAL_ACTION:
      if (action.value === undefined) {
        return state.merge({
          showModal: action.value,
          buttonConditions: {
            text: undefined,
            render: false,
          },
        });
      }
      return state.set('showModal', action.value);
    case ACCEPT_CONDITIONS_ACTION: {
      stateJs.acceptedConditions[stateJs.showModal] = true;
      stateJs.showModal = undefined;
      stateJs.buttonConditions = {
        text: undefined,
        render: false,
      };
      return fromJS(stateJs);
    }
    case SET_CODE_ACTION:
      stateJs.codes[action.nif] = action.code.toUpperCase();
      return fromJS(stateJs);
    case IS_VALID_CONTRACT_ACTION:
      return state.merge({
        idContract: action.idContract,
        documents: action.documents,
      });
    case SEND_FILES_ACTION:
      return state.set('pinIsSending', true);
    case RENEWED_PIN_ACTION:
      return state.merge({
        sendedPin: true,
        pinIsSending: false,
      });
    case IS_PDF_RENDER:
      if (stateJs.acceptedConditions[stateJs.showModal]) {
        return state;
      }
      return state.set('buttonConditions', {
        text: 'Lea el documento para aceptar las condiciones',
        render: true,
      });
    case CANCEL_UPLOAD_ACTION:
      return state.set('pinIsSending', false);
    case SET_LOADED_DRAFT_ACTION:
      return setLoadedDraft(state, action);
    default:
      return state;
  }
}

export default docAndSignatureViewReducer;
