/*
 *
 * NewsViewer actions
 *
 */
import makeActionCreator from 'utils/makeActionCreator';
import {
  LOAD_NEWS,
  SET_NEWS,
  LOAD_WPPOSTS,
  SET_WPPOSTS,
} from './constants';

export const loadNews = makeActionCreator(LOAD_NEWS);
export const setNews = makeActionCreator(SET_NEWS, 'news');
export const loadWPPosts = makeActionCreator(LOAD_WPPOSTS);
export const setWPPosts = makeActionCreator(SET_WPPOSTS, 'wpposts');
