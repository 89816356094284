import { fromJS } from 'immutable';
import { SET_LOADED_DRAFT_ACTION } from 'containers/RecoveryContractModal/constants';
import { initialStateStage } from './mocks/stageMock';
import setLoadedDraft from './mapDraft';
import {
  ADD_INSURED_ACTION,
  DELETE_INSURED_ACTION,
  SET_INSURED_DATA_ACTION,
  ENABLE_EDIT_INSURED_ACTION,
  ENABLE_HEALTH_CONDITION_MODAL_ACTION,
  HEALTH_CONDITION_MODAL_ACTION,
  ENABLE_ADDRESS_DATA_ACTION,
  CHANGE_CONTACT_DATA_ACTION,
  RESET_STATE_INSURED,
  SET_FORM_FIELD_ACTION,
} from './constants';

const baseInsured = {
  healthCondition: false,
  healthConditionData: {},
  showHealthModal: false,
  enabledAddressData: false,
  enabledContactData: true,
  data: {
    nationality: '724',
    country: '724',
  },
};

const initialStateBase = fromJS({
  insureds: [
    {
      isEditing: true,
      isEnabled: true,
      ...baseInsured,
    },
  ],
});

const initialState = process.env.REACT_APP_ENVIRONMENT === 'development' ? initialStateStage : initialStateBase;

function contractingPageReducer(state = initialState, action) {
  const stateJs = state.toJS();
  switch (action.type) {

    case RESET_STATE_INSURED: {
      if (action.product.requiresPolicyHolderAsInsured && !action.product.isMultiInsured) {
        stateJs.insureds[0].data.relationship = 'Es el tomador';
        return fromJS(stateJs);
      }
      return initialState;
    }
    case ADD_INSURED_ACTION: //eslint-disable-line
      const lastInsurance = stateJs.insureds[stateJs.insureds.length - 1];
      let isEditing = false;
      let isEnabled = false;
      if (!lastInsurance.isEditing && lastInsurance.isEnabled) {
        isEditing = true;
        isEnabled = true;
      }
      if (stateJs.insureds[0].data) {
        let data =
          (({ province, town, country, postalCode, direction }) => (
            { province, town, country, postalCode, direction }
          ))(stateJs.insureds[0].data);
        // Merge the direction data and the insureds base data
        data = { ...data, ...baseInsured.data };
        stateJs.insureds.push({ isEditing, isEnabled, ...baseInsured, data });
      } else {
        stateJs.insureds.push({ isEditing, isEnabled, ...baseInsured });
      }
      return fromJS(stateJs);
    case DELETE_INSURED_ACTION:
      stateJs.insureds.splice(action.position, 1);
      if (stateJs.insureds[action.position] !== undefined &&
        !stateJs.insureds[action.position].isEnabled) {
        stateJs.insureds[action.position].isEditing = true;
        stateJs.insureds[action.position].isEnabled = true;
      }
      return fromJS(stateJs);
    case ENABLE_EDIT_INSURED_ACTION:
      stateJs.insureds[action.position].isEditing = true;
      return fromJS(stateJs);
    case SET_INSURED_DATA_ACTION:
      stateJs.insureds[action.position].data = { ...action.values };
      stateJs.insureds[action.position].isEditing = false;
      if (stateJs.insureds[action.position + 1] !== undefined) {
        stateJs.insureds[action.position + 1].isEditing = true;
        stateJs.insureds[action.position + 1].isEnabled = true;
      }
      if (action.position === 0) {
        const addressData =
          (({ province, town, country, postalCode, direction }) => (
            { province, town, country, postalCode, direction }
          ))(stateJs.insureds[0].data);

        for (let i = 1; i < stateJs.insureds.length; i += 1) {
          if (!stateJs.insureds[i].enabledAddressData) {
            Object.assign(stateJs.insureds[i].data, addressData);
          }
        }
      }
      return fromJS(stateJs);
    case SET_FORM_FIELD_ACTION:
      stateJs.insureds[action.position].data[action.field] = action.value;
      return fromJS(stateJs);
    case ENABLE_HEALTH_CONDITION_MODAL_ACTION:
      stateJs.insureds[action.position].showHealthModal = true;
      return fromJS(stateJs);
    case HEALTH_CONDITION_MODAL_ACTION:
      if (action.value) {
        stateJs.insureds[action.position].healthConditionData = action.data;
      }
      stateJs.insureds[action.position].healthCondition = action.value;
      stateJs.insureds[action.position].showHealthModal = false;
      return fromJS(stateJs);
    case ENABLE_ADDRESS_DATA_ACTION:
      stateJs.insureds[action.position].enabledAddressData = true;
      return fromJS(stateJs);
    case CHANGE_CONTACT_DATA_ACTION:
      stateJs.insureds[action.position].enabledContactData = action.value;
      return fromJS(stateJs);
    case SET_LOADED_DRAFT_ACTION:
      return setLoadedDraft(state, action);
    default:
      return state;
  }
}

export default contractingPageReducer;
