import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showRecoveryContractModalAction } from 'containers/RecoveryContractModal/actions';
import IconHelp from 'components/Icons/IconHelp';
import { preventPropagation } from 'utils/functions';
import contact from 'config/contact.config.json';
import logo from 'assets/images/logodivinaseguros.svg';

export class MainHeaderContracting extends React.PureComponent {
  render() {
    return (
      <header className="main-header main-header--contratacion">
        <a className="main-header__logo-wrapper" href="/">
          <img className="main-header__logo" src={logo} alt="Divina Seguros" />
        </a>
        <div className="main-header__option u-only-up-tablet u-uppercase">
          ¿Te ayudamos? Llámanos
        </div>
        <div className="main-header__phone u-only-up-mobile is-numeric">
          {contact.sellPhone}
        </div>
        <div className="main-header__option-button u-margin-bottom-small@only-mobile u-margin-top-small@only-mobile">
          <button
            type="button"
            className="button button--secondary u-valign-middle"
            onClick={this.props.onShowRecoveryModal}
          >
            Recuperar solicitud
          </button>
          <IconHelp
            tooltip="Si quieres recuperar la solicitud que guardaste debes incluir el código y la dirección de correo electrónico a la que enviamos el presupuesto."
            tooltipClassName="u-icon-size-normal"
            preventPropagation={preventPropagation}
          />
        </div>
      </header>
    );
  }
}

MainHeaderContracting.propTypes = {
  showModal: PropTypes.func.isRequired,
  onShowRecoveryModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onShowRecoveryModal: () => dispatch(showRecoveryContractModalAction()),
  };
}

export default connect(null, mapDispatchToProps)(MainHeaderContracting);
